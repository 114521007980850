import { FC } from "react";
import { Stack } from "@mui/material";
import { LargestCostChangesWidgetSetupResourceItem } from "./LargestCostChangesWidgetSetupResourceItem";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { WidgetNumeralView } from "../../../common/view-type/WidgetNumeralView";
import { largestCostChangesSetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/larges-cost-changes/largestCostChangesSetupPropertyByKeySelector";
import { WidgetContentInvalidState } from "../../../common/WidgetContentInvalidState";
import { LargestCostDate, LargestCostPrevDate } from "../../utils/types/types";
import { ResourceExplorerGridTotal } from "../../../../../../../../../../services/cloudchipr.api";

interface LargestCostChangesWidgetBodyProps {
  previousPeriodDate?: LargestCostPrevDate;
  date?: LargestCostDate;
  data: ResourceExplorerGridTotal[];
  loading: boolean;
}

export const LargestCostChangesWidgetBody: FC<
  LargestCostChangesWidgetBodyProps
> = ({ previousPeriodDate, date, data, loading }) => {
  const itemsCount = useAppSelector(
    largestCostChangesSetupPropertyByKeySelector("itemsCount"),
  );

  if (!data.length && !loading) {
    return (
      <WidgetContentInvalidState message="No items match the conditions. Adjust conditions to view results." />
    );
  }

  return (
    <Stack sx={{ overflow: "auto" }} flex={1}>
      <WidgetNumeralView loading={loading} skeletonCount={itemsCount}>
        {data.map((item) => {
          return (
            <LargestCostChangesWidgetSetupResourceItem
              key={item.field + item.cloud_provider}
              provider={item.cloud_provider}
              totalCost={item.total_cost}
              previousPeriodTotalCost={item.previous_period_total_cost}
              difference={
                item.period_total_cost_difference !== null
                  ? Math.abs(item.period_total_cost_difference)
                  : null
              }
              trend={item.trend}
              label={item.label ?? item.field}
              itemsCount={data.length}
              previousPeriodDate={previousPeriodDate}
              date={date}
            />
          );
        })}
      </WidgetNumeralView>
    </Stack>
  );
};
