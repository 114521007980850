import { FC } from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { useAppAbility } from "../../../services/permissions";
import { allAccountsGroupedByProviderAndOrgSelector } from "../../../store/accounts/selectors/all-providers/allAccountsGroupedByProviderAndOrgSelector";
import { Account } from "../../../services/cloudchipr.api";
import { initialAccountsLoadingSelector } from "../../../store/accounts/selectors/loadings/initialAccountsLoadingSelector";
import { useUserRoute } from "../../utils/hooks/useUserRoute.hook";

export const RedirectPage: FC = () => {
  const { cannot } = useAppAbility();
  const cannotView = cannot("view", "account");
  const userLastVisitedPage = useUserRoute(false);
  const accountsLoading = useAppSelector(initialAccountsLoadingSelector);

  const allAccountsGroupedByProviderAndOrg = useAppSelector(
    allAccountsGroupedByProviderAndOrgSelector,
  );
  const awsAccounts = allAccountsGroupedByProviderAndOrg?.aws;
  const gcpAccounts = allAccountsGroupedByProviderAndOrg?.gcp;
  const azureAccounts = allAccountsGroupedByProviderAndOrg?.azure;

  const awsExistingOrg = getExistingOrg(awsAccounts);
  const gcpExistingOrg = getExistingOrg(gcpAccounts);
  const azureExistingOrg = getExistingOrg(azureAccounts);

  if (cannotView) {
    return <Navigate to="/access-denied" replace />;
  }

  if (accountsLoading) {
    return null;
  }

  if (!awsAccounts || !gcpAccounts || !azureAccounts) {
    return null;
  }

  if (userLastVisitedPage) {
    return <Navigate to={userLastVisitedPage} />;
  }

  if (awsExistingOrg) {
    return <Navigate to={`/aws/${awsExistingOrg}/accounts`} />;
  }

  if (gcpExistingOrg) {
    return <Navigate to={`/gcp/${gcpExistingOrg}/accounts`} />;
  }

  if (azureExistingOrg) {
    return <Navigate to={`/azure/${azureExistingOrg}/accounts`} />;
  }

  return <Navigate to="/account/setup" />;
};

const getExistingOrg = (accountsGroupedByOrg?: Record<string, Account[]>) => {
  if (!accountsGroupedByOrg) {
    return "";
  }

  const orgWithAccounts = Object.entries(accountsGroupedByOrg).find(
    ([, accounts]) => {
      return !!accounts.length;
    },
  );

  return orgWithAccounts?.at(0) ?? "";
};
