import { capitalize } from "@mui/material";
import { isStringProviderType } from "../../../../../../utils/helpers/providers/isStringProviderType";
import { getProviderName } from "../../../../../../utils/helpers/providers/getProviderName";
import { ResourceExplorerFilterProvider } from "../../../../../../services/cloudchipr.api";

export const getFilterProviderLabel = (
  filterProvider: ResourceExplorerFilterProvider | "all",
) => {
  if (isStringProviderType(filterProvider)) {
    return getProviderName(filterProvider, { title: true });
  }

  if (filterProvider === "all") {
    return "All Filters";
  }

  if (filterProvider === "dimensions") {
    return "Dimensions";
  }

  if (filterProvider === "global") {
    return "Global";
  }

  return capitalize(filterProvider);
};
