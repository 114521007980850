import { FC } from "react";
import { Card, LinearProgress } from "@mui/material";

import { CostBreakdownV2WidgetSetupChartView } from "./CostBreakdownV2WidgetSetupChartView";
import { CostBreakdownV2WidgetSetupSubHeader } from "../components/CostBreakdownV2WidgetSetupSubHeader";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { widgetSetupContentChartHeight } from "../../../../../../utils/constants/stylings";
import { WidgetSetupContentWrapper } from "../../common/WidgetSetupContentWrapper";
import { CostBreakdownV2WidgetSetupTableView } from "../components/table-view/CostBreakdownV2WidgetSetupTableView";
import { CostBreakdownV2WidgetSetupNumeralView } from "../components/numeral-view/CostBreakdownV2WidgetSetupNumeralView";
import { costBreakdownV2WidgetSetupLoadingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/setup/costBreakdownV2WidgetSetupLoadingSelector";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";

export const CostBreakdownV2WidgetSetupContent: FC = () => {
  const setupChartType = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("viewType"),
  );

  const chartType = setupChartType ?? "line";

  const loading = useAppSelector(costBreakdownV2WidgetSetupLoadingSelector);

  return (
    <WidgetSetupContentWrapper>
      <Card
        elevation={3}
        sx={{
          height: widgetSetupContentChartHeight,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {loading && <LinearProgress />}

        <CostBreakdownV2WidgetSetupSubHeader />

        {chartType === "table" && <CostBreakdownV2WidgetSetupTableView />}

        {chartType === "numeral" && <CostBreakdownV2WidgetSetupNumeralView />}

        {!["numeral", "table"].includes(chartType) && (
          <CostBreakdownV2WidgetSetupChartView />
        )}
      </Card>
    </WidgetSetupContentWrapper>
  );
};
