import { FC, useCallback } from "react";
import { Menu, PopoverOrigin, Stack } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { DashboardV2ItemMoveActionMenuSection } from "./DashboardV2ItemMoveActionMenuSection";
import { useAppDispatch } from "../../../../../../../../store/hooks";
import { updateDashboardHierarchyThunk } from "../../../../../../../../store/dashboards/thunks/dashboard-hierarchy/updateDashboardHierarchyThunk";
import { navigationHierarchyItemNoDndKey } from "../../../../../sortable-tree/utils/constants";
import { updateDashboardVisibilityHierarchyThunk } from "../../../../../../../../store/dashboards/thunks/dashboard-hierarchy-visibility/updateDashboardVisibilityHierarchyThunk";
import { NavigationItemsVisibilityType } from "../../../../../../../../store/navigation/utils/types";

interface DashboardV2ItemMoveActionMenuProps {
  id: string;
  name: string;
  open: boolean;
  onClose(): void;
  anchor: HTMLElement | null;
  visibility: NavigationItemsVisibilityType;
  folderId?: string;
}

export const DashboardV2ItemMoveActionMenu: FC<
  DashboardV2ItemMoveActionMenuProps
> = ({ id, folderId, anchor, onClose, open, visibility, name }) => {
  const dispatch = useAppDispatch();
  const enableNavigationHierarchyVisibility = useFlag(
    "EnableNavigationHierarchyVisibility",
  );

  const moveToFolder = useCallback(
    async (visibility: NavigationItemsVisibilityType, folderId?: string) => {
      if (enableNavigationHierarchyVisibility) {
        await dispatch(
          updateDashboardVisibilityHierarchyThunk({
            id,
            folderId,
            visibility,
            type: "dashboard",
          }),
        );
      } else {
        await dispatch(
          updateDashboardHierarchyThunk({
            folderId,
            id,
          }),
        );
      }

      onClose();
    },
    [id, dispatch, onClose, enableNavigationHierarchyVisibility],
  );

  const moveToRoot = useCallback(
    async (visibility: NavigationItemsVisibilityType) => {
      if (enableNavigationHierarchyVisibility) {
        await dispatch(
          updateDashboardVisibilityHierarchyThunk({
            id,
            index: 0,
            visibility,
            type: "dashboard",
          }),
        );
      } else {
        await dispatch(
          updateDashboardHierarchyThunk({
            id,
            index: 0,
          }),
        );
      }

      onClose();
    },
    [id, dispatch, onClose, enableNavigationHierarchyVisibility],
  );

  return (
    <Menu
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      open={open}
      onClose={onClose}
      anchorEl={anchor}
      slotProps={slotProps}
      {...navigationHierarchyItemNoDndKey}
    >
      {enableNavigationHierarchyVisibility ? (
        <Stack spacing={1}>
          <DashboardV2ItemMoveActionMenuSection
            folderId={folderId}
            currentDashboardName={name}
            visibility="visible_to_everyone"
            currentVisibility={visibility}
            moveToFolder={moveToFolder}
            moveToRoot={moveToRoot}
          />
          <DashboardV2ItemMoveActionMenuSection
            folderId={folderId}
            currentDashboardName={name}
            visibility="visible_only_to_me"
            currentVisibility={visibility}
            moveToFolder={moveToFolder}
            moveToRoot={moveToRoot}
          />
        </Stack>
      ) : (
        <DashboardV2ItemMoveActionMenuSection
          hideTitle
          folderId={folderId}
          visibility={visibility}
          currentDashboardName={name}
          currentVisibility={visibility}
          moveToFolder={moveToFolder}
          moveToRoot={moveToRoot}
        />
      )}
    </Menu>
  );
};

const slotProps = {
  paper: {
    sx: {
      minWidth: 200,
      maxWidth: 400,
      maxHeight: 400,
    },
  },
};
const anchorOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};
const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};
