import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { RootState } from "../../../../../store";
import { largestCostChangesWidgetByIdSelector } from "../../../../selectors/widgets/largest-cost-changes/largestCostChangesWidgetByIdSelector";
import { cloudChiprApi } from "../../../../../../services/cloudchipr.api";
import { largestCostChangesWidgetNotFoundSelector } from "../../../../selectors/widgets/largest-cost-changes/largestCostChangesWidgetNotFoundSelector";

export const getLargestCostChangesWidgetDataByWidgetIdThunk = createAsyncThunk(
  "dashboards/getLargestCostChangesWidgetDataByWidgetId",
  async (widgetId: string, { dispatch, getState }) => {
    const state = getState() as RootState;

    const widget = largestCostChangesWidgetByIdSelector(state, widgetId);
    const viewNotFound = largestCostChangesWidgetNotFoundSelector(
      state,
      widgetId,
    );

    const {
      getUsersMeOrganisationsCurrentWidgetsLargestCostChangesByWidgetId,
    } = cloudChiprApi.endpoints;

    if (!widget || viewNotFound) {
      return;
    }

    try {
      return await dispatch(
        getUsersMeOrganisationsCurrentWidgetsLargestCostChangesByWidgetId.initiate(
          { widgetId },
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: api fix
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });
    }
  },
);
