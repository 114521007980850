import { liveUsageMgmtResourceTypesHierarchySelector } from "./liveUsageMgmtResourceTypesHierarchySelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";

export const liveUsageMgmtSelectedRowsWithHierarchyByResourceTypeSelector = (
  state: RootState,
  resourceType?: ResourceType,
): Record<ResourceType, Record<string, boolean>> | undefined => {
  if (!resourceType) {
    return;
  }

  const resourceTypesHierarchy = liveUsageMgmtResourceTypesHierarchySelector(
    state,
    resourceType,
  );

  return resourceTypesHierarchy.reduce(
    (result, item) => {
      const data = Object.values(state.liveUsageMgmt.selected[item] ?? {});

      const values = Object.values(data);
      values.forEach((member) => {
        if (!Object.keys(member).length) {
          return;
        }
        const data = result?.[item]
          ? { ...result[item], ...member }
          : { ...member };
        result = {
          ...result,
          [item]: {
            ...data,
          },
        };
      });

      return result;
    },
    {} as Record<ResourceType, Record<string, boolean>>,
  );
};
