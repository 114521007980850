import { KeyValuePair } from "../../../../services/cloudchipr.api";
import { FilterTreeNodeWithId } from "../../../../components/pages/common/filters-tree/utils/types/common";
import { isTreeNodeFilterGroup } from "../../../../components/pages/common/filters-tree/utils/helpers/isTreeNodeFilterGroup";
import { filterAvailableFieldsChecker } from "../../../../components/pages/common/filters-tree/utils/helpers/data-type-checkers/filterAvailableFieldsChecker";

export const categoryFilterTreeIsInvalid = (filter: FilterTreeNodeWithId) => {
  if (isTreeNodeFilterGroup(filter)) {
    return filter.items.some(categoryFilterTreeIsInvalid);
  }

  const { hasKey, hasValue } = filterAvailableFieldsChecker(
    filter.type,
    filter.operator,
  );

  if (!hasValue) {
    return hasKey ? !(filter.value as KeyValuePair)?.key : false;
  }

  const filterValue = hasKey
    ? (filter.value as KeyValuePair)?.value
    : filter.value;

  if (typeof filterValue === "number") {
    return !filter.value;
  }

  if (typeof filterValue === "string" || Array.isArray(filterValue)) {
    return !filterValue?.length;
  }

  return !filterValue;
};
