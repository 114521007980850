import { FC } from "react";
import { FilterValueInput } from "./FilterValueInput";
import { FilterValueAutocomplete } from "./FilterValueAutocomplete";
import { FilterValueByKeyAutocomplete } from "./FilterValueByKeyAutocomplete";
import { FilterValueSingleSelect } from "./FilterValueSingleSelect";
import { KeyValuePair } from "../../../../../../../../../services/cloudchipr.api";
import { getFilterValueDataTypeByOperator } from "../../../../../utils/helpers/data-type-checkers/getFilterValueDataTypeByOperator";
import { filterValueIsSingleSelectByOperator } from "../../../../../utils/helpers/data-type-checkers/filterValueIsSingleSelectByOperator";
import { FilterItemNodeWithId } from "../../../../../utils/types/common";
import { filterAvailableFieldsChecker } from "../../../../../utils/helpers/data-type-checkers/filterAvailableFieldsChecker";

interface FilterValueProps {
  filter: FilterItemNodeWithId;
  onChange(value: FilterItemNodeWithId["value"]): void;
}

export const FilterValue: FC<FilterValueProps> = ({ filter, onChange }) => {
  const { hasKey } = filterAvailableFieldsChecker(filter.type, filter.operator);
  const valueType = getFilterValueDataTypeByOperator(filter.operator);
  const filterValue = hasKey
    ? (filter.value as KeyValuePair)?.value
    : filter.value;

  const valueIsPrimitive = valueType === "string";

  if (valueIsPrimitive) {
    if (filterValueIsSingleSelectByOperator(filter.operator)) {
      return (
        <FilterValueSingleSelect
          onChange={onChange}
          filterValue={filterValue as string}
          filterType={filter.type}
          provider={filter.filter_provider}
        />
      );
    } else {
      return <FilterValueInput onChange={onChange} filter={filter} />;
    }
  }

  if (hasKey) {
    return (
      <FilterValueByKeyAutocomplete
        onChange={onChange}
        filterType={filter.type}
        provider={filter.filter_provider}
        value={filter?.value as KeyValuePair}
      />
    );
  }

  return (
    <FilterValueAutocomplete
      onChange={onChange}
      filterValue={filterValue as string[]}
      filterType={filter.type}
      provider={filter.filter_provider}
    />
  );
};
