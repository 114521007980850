import { FC } from "react";
import { Stack } from "@mui/material";
import { CommitmentsTableName } from "./CommitmentsTableName";
import { CopyIconButton } from "../../../../../../common/CopyIconButton";
import { useHover } from "../../../../../../../utils/hooks/useHover.hook";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";

interface ProviderUniqueIdCellProps {
  id: string;
}

export const ProviderUniqueIdCell: FC<ProviderUniqueIdCellProps> = ({ id }) => {
  const { ref, hovered } = useHover();

  return (
    <Stack direction="row" spacing={1} alignItems="center" ref={ref}>
      <CommitmentsTableName
        id={id}
        type="commitment"
        name={<TypographyWithTooltip title={id} variant="body2" />}
      />

      <CopyIconButton data={id} visible={hovered} />
    </Stack>
  );
};
