import { FC } from "react";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import MenuList from "@mui/material/MenuList";
import { FiltersGroupType } from "../../../utils/types/common";
import { FilterProviderIcon } from "../../FilterProviderIcon";
import { getFilterProviderLabel } from "../../../utils/helpers/getFilterProviderLabel";

interface GroupSelectProps {
  group: FiltersGroupType;
  groupOptions: Record<FiltersGroupType, number>;
  setGroup(group: FiltersGroupType): void;
}

export const GroupSelect: FC<GroupSelectProps> = ({
  groupOptions,
  group,
  setGroup,
}) => {
  return (
    <Stack p={1} width={170} height={350} overflow="auto">
      <MenuList disablePadding>
        {Object.keys(groupOptions).map((key) => {
          const groupKey = key as FiltersGroupType;

          return (
            <MenuItem
              key={groupKey}
              sx={menuItemSx}
              selected={group === groupKey}
              onClick={setGroup.bind(null, groupKey)}
            >
              <ListItemIcon>
                <FilterProviderIcon filterProvider={groupKey} />
              </ListItemIcon>

              <Stack direction="row" flex={1} justifyContent="space-between">
                <ListItemText
                  primaryTypographyProps={{
                    variant: "subtitle2",
                    fontWeight: "normal",
                  }}
                  primary={getFilterProviderLabel(groupKey)}
                />

                <Stack
                  width={24}
                  borderRadius={1}
                  alignItems="center"
                  bgcolor="primary.light"
                >
                  <Typography variant="subtitle2" color="primary">
                    {groupOptions[groupKey]}
                  </Typography>
                </Stack>
              </Stack>
            </MenuItem>
          );
        })}
      </MenuList>
    </Stack>
  );
};

const menuItemSx = {
  px: 0.5,
  my: 1,
  borderRadius: 2,
  "&.Mui-selected": { color: "primary.main" },
};
