import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { costAndUsageSumSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-and-usage/costAndUsageSumSetupPropertyByKeySelector";
import { widgetViewNameByViewIdIdSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/common/widgetViewNameByViewIdIdSelector";
import { WidgetSetupSubHeader } from "../../common/WidgetSetupSubHeader";
import { objectToUrl } from "../../../../../../../../utils/helpers/dataToUrlToDataConvertors";

export const CostAndUsageSumWidgetSetupHeader: FC = () => {
  const name = useAppSelector(
    costAndUsageSumSetupPropertyByKeySelector("name"),
  );
  const date = useAppSelector(
    costAndUsageSumSetupPropertyByKeySelector("dates"),
  );
  const viewId = useAppSelector(
    costAndUsageSumSetupPropertyByKeySelector("viewId"),
  );

  const viewName = useAppSelector((state) =>
    widgetViewNameByViewIdIdSelector(state, viewId),
  );

  const path = objectToUrl([
    {
      dates: date,
    },
  ]);

  return (
    <WidgetSetupSubHeader
      name={name}
      icon="pie"
      date={date}
      title={viewName ?? ""}
      menuTitle="Open in Resource Explorer"
      menuUrl={`/resource-explorer/${viewId}?path=${path}`}
    />
  );
};
