import { FC } from "react";
import { Card, Divider, LinearProgress } from "@mui/material";
import { LargestCostChangesWidgetSetupHeader } from "./components/LargestCostChangesWidgetSetupHeader";
import { LargestCostChangesWidgetBody } from "./components/LargestCostChangesWidgetBody";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { largestCostChangesSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/larges-cost-changes/largestCostChangesSetupPropertyByKeySelector";
import { widgetSetupContentChartHeight } from "../../../../../../utils/constants/stylings";
import { widgetREPrevDateDetailsSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/common/widget-re-view/WidgetREPrevDateDetailsSelector";
import { LargestCostChangesWidgetFooter } from "../../../../../widgets/largest-cost-change/components/footer/LargestCostChangesWidgetFooter";
import { WidgetSetupContentWrapper } from "../../common/WidgetSetupContentWrapper";
import { largesCostChangesSetupWidgetDataLoadingSelector } from "../../../../../../../../../store/dashboards/selectors/setups/larges-cost-changes/data/largesCostChangesSetupWidgetDataLoadingSelector";
import { largesCostChangesSetupWidgetDataSelector } from "../../../../../../../../../store/dashboards/selectors/setups/larges-cost-changes/data/largesCostChangesSetupWidgetDataSelector";
import { buildingInProgressWidgetId } from "../../../../../../../../../store/dashboards/thunks/widgets/utils/constants";

export const LargestCostChangesWidgetSetupContent: FC = () => {
  const loading = useAppSelector(
    largesCostChangesSetupWidgetDataLoadingSelector,
  );

  const data = useAppSelector(largesCostChangesSetupWidgetDataSelector);

  const prevDate = useAppSelector((state) =>
    widgetREPrevDateDetailsSelector(state, buildingInProgressWidgetId),
  );

  const date = useAppSelector(
    largestCostChangesSetupPropertyByKeySelector("dates"),
  );

  return (
    <WidgetSetupContentWrapper>
      <Card elevation={3} sx={cardSx}>
        {loading && <LinearProgress />}

        <LargestCostChangesWidgetSetupHeader />

        <Divider />

        <LargestCostChangesWidgetBody
          previousPeriodDate={prevDate}
          date={date}
          loading={loading}
          data={data ?? []}
        />

        {!loading && !!data?.length && (
          <LargestCostChangesWidgetFooter prevDate={prevDate} date={date} />
        )}
      </Card>
    </WidgetSetupContentWrapper>
  );
};

const cardSx = {
  height: widgetSetupContentChartHeight,
  display: "flex",
  flexDirection: "column",
};
