import { createAsyncThunk } from "@reduxjs/toolkit";
import { CategoryWithCosts } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { dimensionIdSelector } from "../../../selectors/slice-data/dimensionIdSelector";
import { dimensionCategoriesEntriesSelector } from "../../../selectors/slice-data/dimensionCategoriesEntriesSelector";
import { createCategoryByDimensionIdThunk } from "../../categories/createCategoryByDimensionIdThunk";
import { convertDynamicFiltersToCategoryFilters } from "../../../../../components/pages/dimensions/utils/helpers/convertDynamicFiltersToCategoryFilters";

export const findAndCreateNewAddedCategoriesThunk = createAsyncThunk(
  "dimensions/findAndCreateNewAddedCategories",
  async (
    originalCategoriesByIds: Record<string, CategoryWithCosts>,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const dimensionId = dimensionIdSelector(state);
    const dimensionCategories = dimensionCategoriesEntriesSelector(state);

    const newAddedCategories = dimensionCategories.filter(
      ([id]) => !originalCategoriesByIds[id],
    );

    if (!dimensionId || !newAddedCategories?.length) {
      return;
    }

    const promises = newAddedCategories.map(([id, category]) => {
      return dispatch(
        createCategoryByDimensionIdThunk({
          dimensionId,
          categoryId: id,
          categoryName: category.name,
          filters: {
            filters: category.filters
              ? convertDynamicFiltersToCategoryFilters(category.filters).filters
              : undefined,
            filter_tree: category.filter_tree,
          },
        }),
      );
    });

    await Promise.all(promises);
  },
);
