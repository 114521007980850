import { allAccountsGroupedByProviderSelector } from "./all-providers/allAccountsGroupedByProviderSelector";
import { RootState } from "../../store";
import { ProviderType } from "../../../services/cloudchipr.api";
import { activeAccountStatuses } from "../../../utils/constants/accounts";

export const firstActiveAccountIdByProviderSelector = (
  state: RootState,
  provider: ProviderType,
): string | undefined => {
  const accounts = allAccountsGroupedByProviderSelector(state);

  if (!accounts) {
    return;
  }
  const filteredAccounts = accounts[provider];

  const activeAccount = filteredAccounts?.find((item) =>
    activeAccountStatuses.has(item.status),
  );

  return activeAccount?.id;
};
