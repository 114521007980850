import { FC, Fragment, ReactNode } from "react";
import { useToggle } from "rooks";
import {
  Collapse,
  ListItemButtonProps,
  ListItemProps,
  ListItemTextProps,
  SvgIconProps,
  Theme,
} from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import { SxProps } from "@mui/system";
import { NavItemCollapsed } from "./components/NavItemCollapsed";
import { NavItemExpanded } from "./components/NavItemExpanded";

export interface NavItemProps {
  primary: ListItemTextProps["primary"];
  primaryTypographyProps?: ListItemTextProps["primaryTypographyProps"];
  icon?: SvgIconComponent;
  iconProps?: SvgIconProps;
  selected?: boolean;
  disabled?: boolean;
  to?: string;
  tooltipTitle?: string;
  onButtonClick?: ListItemButtonProps["onClick"];
  secondaryAction?: ListItemProps["secondaryAction"];
  secondaryActionHover?: boolean;
  nested?: boolean;
  expandable?: boolean;
  expanded?: boolean;
  navCollapsed?: boolean;
  comingSoon?: boolean | ReactNode;
  children?: ReactNode[];
  listItemButtonSx?: SxProps<Theme>;
}

export const NavItem: FC<NavItemProps> = ({
  primary,
  icon: Icon,
  children,
  nested,
  navCollapsed,
  tooltipTitle,
  selected,
  ...rest
}) => {
  const [stateExpandedState, toggleExpandedState] = useToggle(selected);

  if (navCollapsed) {
    return (
      <NavItemCollapsed
        primary={primary}
        tooltipTitle={tooltipTitle}
        icon={Icon}
        children={children}
        nested={nested}
        selected={selected}
        {...rest}
      />
    );
  }

  return (
    <Fragment>
      <NavItemExpanded
        primary={primary}
        icon={Icon}
        onButtonClick={toggleExpandedState}
        expandable={!!children?.length}
        expanded={stateExpandedState}
        nested={nested}
        tooltipTitle={tooltipTitle}
        selected={selected}
        {...rest}
      />
      {children && !navCollapsed && (
        <Collapse in={stateExpandedState}>{children}</Collapse>
      )}
    </Fragment>
  );
};
