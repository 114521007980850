import { FC } from "react";
import { Stack } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { AddFilterButton } from "./components/AddFilterButton";
import { ResourceExplorerFilterItems } from "./components/filter-items/ResourceExplorerFilterItems";
import { ResourceExplorerProviders } from "../providers/ResourceExplorerProviders";
import { useAppSelector } from "../../../../../store/hooks";
import { isBillingAvailableSelector } from "../../../../../store/common/selectors/billing-status/isBillingAvailableSelector";
import { FilterByDimensionButton } from "../dimensions-filters/components/FilterByDimensionButton";
import { ResourceExplorerDimensionFilterItem } from "../dimensions-filters/components/ResourceExplorerDimensionFilterItem";
import { enabledProvidersByBillingStatusSelector } from "../../../../../store/common/selectors/billing-status/enabledProvidersByBillingStatusSelector";

interface ResourceExplorerFiltersProps {
  viewId: string;
}

export const ResourceExplorerFilters: FC<ResourceExplorerFiltersProps> = ({
  viewId,
}) => {
  const enableDimensions = useFlag("EnableDimensions");

  const enabledProviders = useAppSelector(
    enabledProvidersByBillingStatusSelector,
  );
  const isResourceExplorerAvailable = useAppSelector(
    isBillingAvailableSelector,
  );

  if (!isResourceExplorerAvailable) {
    return null;
  }

  return (
    <Stack
      spacing={1}
      mt={1}
      px={2}
      py={1}
      borderBottom={1}
      borderColor="grey.300"
    >
      {enabledProviders.length > 1 && (
        <Stack direction="row" spacing={1} alignItems="center">
          <ResourceExplorerProviders />
          <AddFilterButton />

          {enableDimensions && <FilterByDimensionButton />}
        </Stack>
      )}

      <ResourceExplorerFilterItems viewId={viewId} />

      <ResourceExplorerDimensionFilterItem />
    </Stack>
  );
};
