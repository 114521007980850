import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { liveUsageMgmtProviderSelector } from "../../selectors/store-selectors/liveUsageMgmtProviderSelector";
import { liveUsageMgmtAppliedFiltersSelector } from "../../selectors/store-selectors/liveUsageMgmtAppliedFiltersSelector";
import { liveUsageMgmtAccountIdsSelector } from "../../selectors/store-selectors/liveUsageMgmtAccountIdsSelector";
import { getOrganisationCurrentFilterTemplatesThunk } from "../getOrganisationCurrentFilterTemplatesThunk";
import {
  setLiveUsageMgmtAppliedFilters,
  setLiveUsageMgmtAppliedFilterTemplateId,
} from "../../liveUsageMgmtSlice";

export const createLiveUsageMgmtFilterTemplateFromAppliedFiltersThunk =
  createAsyncThunk(
    "liveUsageMgmt/createLiveUsageMgmtFilterTemplateFromAppliedFilters",
    async (name: string, { dispatch, getState }) => {
      const state = getState() as RootState;

      const provider = liveUsageMgmtProviderSelector(state);
      const appliedFilters = liveUsageMgmtAppliedFiltersSelector(state);
      const accountIds = liveUsageMgmtAccountIdsSelector(state);

      const { postUsersMeOrganisationsCurrentFilterSetsV2 } =
        cloudChiprApi.endpoints;

      if (!appliedFilters || !provider) {
        return;
      }

      const filters = Object.values(appliedFilters);

      if (!filters) {
        return;
      }

      try {
        const response = await dispatch(
          postUsersMeOrganisationsCurrentFilterSetsV2.initiate({
            body: {
              name,
              filters,
              cloud_provider: provider,
              accounts_to_apply: accountIds ?? null,
            },
          }),
        ).unwrap();

        if (response?.id) {
          dispatch(setLiveUsageMgmtAppliedFilterTemplateId(response.id));
          response.filters.forEach((item) => {
            dispatch(setLiveUsageMgmtAppliedFilters(item));
          });
          await dispatch(getOrganisationCurrentFilterTemplatesThunk());
        }

        return response;
      } catch (e) {
        // @ts-expect-error //todo: remove this when backend will add types
        enqueueSnackbar(e?.data?.message ?? "Something went wrong", {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "error",
          },
        });
      }
    },
  );
