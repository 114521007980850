import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { applyLiveUsageMgmtFilterTemplateThunk } from "./applyLiveUsageMgmtFilterTemplateThunk";
import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { liveUsageMgmtFilterTemplateNameByIdSelector } from "../../selectors/liveUsageMgmtFilterTemplateNameByIdSelector";
import { liveUsageMgmtAccountIdsSelector } from "../../selectors/store-selectors/liveUsageMgmtAccountIdsSelector";
import { liveUsageMgmtAppliedFiltersSelector } from "../../selectors/store-selectors/liveUsageMgmtAppliedFiltersSelector";
import { liveUsageMgmtProviderSelector } from "../../selectors/store-selectors/liveUsageMgmtProviderSelector";
import { getAccountsThunk } from "../../../accounts/thunks/getAccountsThunk";
import { getLiveUsageMgmtResourcesWithFiltersByProviderThunk } from "../resources/getLiveUsageMgmtResourcesWithFiltersByProviderThunk";
import { getOrganisationCurrentFilterTemplatesThunk } from "../getOrganisationCurrentFilterTemplatesThunk";
import { liveUsageMgmtAppliedTemplateIdSelector } from "../../selectors/store-selectors/liveUsageMgmtAppliedTemplateIdSelector";
import { setLiveUsageMgmtAppliedFilterTemplateId } from "../../liveUsageMgmtSlice";

export const patchLiveUsageMgmtFilterTemplateFromAppliedFiltersThunk =
  createAsyncThunk(
    "liveUsageMgmt/patchLiveUsageMgmtFilterTemplateFromAppliedFilters",
    async (filterTemplateId: string, { dispatch, getState }) => {
      const state = getState() as RootState;

      const accountIds = liveUsageMgmtAccountIdsSelector(state);
      const appliedFilters = liveUsageMgmtAppliedFiltersSelector(state);
      const provider = liveUsageMgmtProviderSelector(state);
      const appliedFilterTemplateId =
        liveUsageMgmtAppliedTemplateIdSelector(state);
      const filterTemplateName = liveUsageMgmtFilterTemplateNameByIdSelector(
        state,
        filterTemplateId,
      );

      const { patchUsersMeOrganisationsCurrentFilterSetsByFilterSetIdV2 } =
        cloudChiprApi.endpoints;

      if (!accountIds || !provider || !appliedFilters) {
        return;
      }

      try {
        const response = await dispatch(
          patchUsersMeOrganisationsCurrentFilterSetsByFilterSetIdV2.initiate({
            filterSetId: filterTemplateId,
            body: {
              name: filterTemplateName,
              filters: Object.values(appliedFilters),
              cloud_provider: provider,
            },
          }),
        ).unwrap();

        if (response?.id) {
          if (appliedFilterTemplateId !== filterTemplateId) {
            await dispatch(
              applyLiveUsageMgmtFilterTemplateThunk({
                filterTemplateId,
                accountIds,
              }),
            );
            dispatch(setLiveUsageMgmtAppliedFilterTemplateId(filterTemplateId));
          }
          await dispatch(getAccountsThunk());
          await dispatch(getOrganisationCurrentFilterTemplatesThunk());
          response?.filters?.forEach(({ type }) => {
            dispatch(getLiveUsageMgmtResourcesWithFiltersByProviderThunk(type));
          });
        }

        return response;
      } catch (e) {
        // @ts-expect-error //todo: remove this when backend will add types
        enqueueSnackbar(e?.data?.message ?? "Something went wrong", {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "error",
          },
        });
      }
    },
  );
