import { ChangeEvent, FC, useCallback } from "react";
import { TextField } from "@mui/material";
import { DynamicProviderFilterItem } from "../../../../../utils/types/common";
import {
  KeyValuePair,
  ResourceExplorerDynamicFilterItem,
} from "../../../../../../../../../services/cloudchipr.api";
import { filterAvailableFieldsChecker } from "../../../../../../filters-tree/utils/helpers/data-type-checkers/filterAvailableFieldsChecker";

interface FilterValueInputProps {
  filter: DynamicProviderFilterItem;
  onChange(value: ResourceExplorerDynamicFilterItem["value"]): void;
}

export const FilterValueInput: FC<FilterValueInputProps> = ({
  filter,
  onChange,
}) => {
  const { hasKey } = filterAvailableFieldsChecker(filter.type, filter.operator);
  const filterValue = (
    hasKey ? (filter.value as KeyValuePair)?.value : filter.value
  ) as string;

  const filterValueKey = (filter.value as KeyValuePair)?.key;

  const inputValueChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;

      if (hasKey) {
        onChange({ key: filterValueKey, value: val });
      } else {
        onChange(val);
      }
    },
    [onChange, filterValueKey, hasKey],
  );

  return (
    <TextField
      fullWidth
      label="Value"
      size="xsmall"
      value={filterValue ?? ""}
      onChange={inputValueChangeHandler}
    />
  );
};
