import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { Eks } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const eksColumns: ColumnSetupType<Eks>[] = [
  {
    accessorKey: "name",
    header: "Name",
    size: 280,
    type: "identifierWithRecommendationLink",
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "account",
    header: "Account",
    type: "account",
    size: 210,
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "instances_count",
    header: "Node Groups",
  },
  {
    accessorKey: "node_count",
    header: "Nodes",
  },
  {
    accessorKey: "created_at",
    header: "Launch time",
    type: "date",
  },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  { accessorKey: "tags", header: "Tags", type: "tag", enableSorting: false },
];
