import { createAsyncThunk } from "@reduxjs/toolkit";

import { findAndApplyFilterTemplatesThunk } from "./filters/findAndApplyLiveUsageMgmtFiltersThunk";
import { getOrganisationCurrentFilterTemplatesThunk } from "./getOrganisationCurrentFilterTemplatesThunk";
import { getLiveUsageMgmtAccountsVisibilitiesThunk } from "./getLiveUsageMgmtAccountsVisibilitiesThunk";
import { getLiveUsageMgmtAllResourceTypesDataThunk } from "./resources/getLiveUsageMgmtAllResourceTypesDataThunk";
import {
  setAccountIds,
  setLiveUsageMgmtPageState,
  setLiveUsageMgmtProvider,
} from "../liveUsageMgmtSlice";
import { generateAccountIdsFromSearchParams } from "../../../components/pages/live-usage-mgmt/utils/helpers/generateAccountIdsFromSearchParams";
import { AccountId, ProviderType } from "../../../services/cloudchipr.api";

interface InitiateLiveUsageMgmtThunkArgs {
  accountIds: AccountId | null;
  provider: ProviderType;
}

export const initiateLiveUsageMgmtThunk = createAsyncThunk(
  "liveUsageMgmt/initiateLiveUsageMgmtThunk",
  async (
    { accountIds, provider }: InitiateLiveUsageMgmtThunkArgs,
    { dispatch },
  ) => {
    dispatch(setLiveUsageMgmtPageState("pending"));
    dispatch(setAccountIds(generateAccountIdsFromSearchParams(accountIds)));
    dispatch(setLiveUsageMgmtProvider(provider));

    await dispatch(getOrganisationCurrentFilterTemplatesThunk());

    await dispatch(findAndApplyFilterTemplatesThunk());

    await dispatch(getLiveUsageMgmtAccountsVisibilitiesThunk());
    await dispatch(getLiveUsageMgmtAllResourceTypesDataThunk());
    dispatch(setLiveUsageMgmtPageState("fulfilled"));
  },
);
