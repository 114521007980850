import { createAsyncThunk } from "@reduxjs/toolkit";
import { WidgetType } from "../../../../../services/cloudchipr.api";
import { editCostByCategoryWidgetThunk } from "../cost-by-category/actions/editCostByCategoryWidgetThunk";
import { editCostAndUsageSumWidgetThunk } from "../cost-and-usage/actions/editCostAndUsageSumWidgetThunk";
import { editLargestCostChangesWidgetThunk } from "../largest-cost-changes/actions/editLargestCostChangesWidgetThunk";
import { editCostBreakdownV2WidgetThunk } from "../cost-breakdown-v2/actions/editCostBreakdownV2WidgetThunk";

interface EditWidgetThunkArgs {
  widgetId: string;
  widgetType: WidgetType;
}
export const editWidgetThunkV2 = createAsyncThunk(
  "dashboards/editWidget",
  ({ widgetId, widgetType }: EditWidgetThunkArgs, { dispatch }) => {
    if (widgetType === "costs_by_category_v2") {
      dispatch(editCostByCategoryWidgetThunk(widgetId));
    }
    if (widgetType === "cost_breakdown") {
      dispatch(editCostBreakdownV2WidgetThunk(widgetId));
    }
    if (widgetType === "cost_and_usage_summary") {
      dispatch(editCostAndUsageSumWidgetThunk(widgetId));
    }
    if (widgetType === "largest_cost_changes") {
      dispatch(editLargestCostChangesWidgetThunk(widgetId));
    }
  },
);
