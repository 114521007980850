import { FC, Fragment } from "react";
import { useDidMount } from "rooks";
import { Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { WidgetLoadingState } from "../common/WidgetLoadingState";
import { getCostAndUsageSumWidgetDataByWidgetIdThunk } from "../../../../../../store/dashboards/thunks/widgets/cost-and-usage/data-get/getCostAndUsageSumWidgetDataByWidgetIdThunk";
import { CostAndUsageSumWidgetView } from "../../adding-widget/widget-create/widget-setups/cost-and-usage/content/CostAndUsageSumWidgetView";
import { costAndUsageSumWidgetTitlePropsSelector } from "../../../../../../store/dashboards/selectors/widgets/cost-and-usage/costAndUsageSumWidgetTitlePropsSelector";
import { WidgetCardHeader } from "../common/widget-header/WidgetCardHeader";
import { useHover } from "../../../../../../utils/hooks/useHover.hook";
import { costAndUsageWidgetNotFoundSelector } from "../../../../../../store/dashboards/selectors/widgets/cost-and-usage/costAndUsageWidgetNotFoundSelector";
import { DashboardNotFoundWidget } from "../common/DashboardNotFoundWidget";
import { costAndUsageSummaryWidgetViewNameSelector } from "../../../../../../store/dashboards/selectors/widgets/cost-and-usage/costAndUsageSummaryWidgetViewNameSelector";
import { costAndUSageSumWidgetViewDataSelector } from "../../../../../../store/dashboards/selectors/widgets/cost-and-usage/widget-data/costAndUSageSumWidgetViewDataSelector";
import { costAndUsageSumWidgetDataLoadingSelector } from "../../../../../../store/dashboards/selectors/widgets/cost-and-usage/widget-data/costAndUsageSumWidgetDataLoadingSelector";

interface DashboardCostAndUsageSumWidgetProps {
  widgetId: string;
}
export const DashboardCostAndUsageSumWidget: FC<
  DashboardCostAndUsageSumWidgetProps
> = ({ widgetId }) => {
  const dispatch = useAppDispatch();
  const { ref, hovered } = useHover();

  const widgetDataNotFound = useAppSelector((state) =>
    costAndUsageWidgetNotFoundSelector(state, widgetId),
  );
  const loading = useAppSelector((state) =>
    costAndUsageSumWidgetDataLoadingSelector(state, widgetId),
  );

  const data = useAppSelector((state) =>
    costAndUSageSumWidgetViewDataSelector(state, widgetId),
  );

  const viewName = useAppSelector((state) =>
    costAndUsageSummaryWidgetViewNameSelector(state, widgetId),
  );

  const widgetTitleProps = useAppSelector((state) =>
    costAndUsageSumWidgetTitlePropsSelector(state, widgetId),
  );

  useDidMount(() => {
    dispatch(getCostAndUsageSumWidgetDataByWidgetIdThunk(widgetId));
  });

  return (
    <Stack ref={ref} flexGrow={1} overflow="auto">
      {loading ? (
        <WidgetLoadingState />
      ) : (
        <Fragment>
          <WidgetCardHeader
            hovered={hovered}
            widgetId={widgetId}
            widgetType="cost_and_usage_summary"
            widgetTitleProps={widgetTitleProps}
          />

          {widgetDataNotFound ? (
            <DashboardNotFoundWidget
              name={viewName ?? ""}
              label="resource explorer view"
            />
          ) : (
            <CostAndUsageSumWidgetView
              data={data ?? []}
              loading={loading}
              dateLabel={widgetTitleProps?.dates?.label}
            />
          )}
        </Fragment>
      )}
    </Stack>
  );
};
