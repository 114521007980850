import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  FilterOperatorType,
  ResourceExplorerDynamicFilterItemType,
  ResourceExplorerFilterProvider,
} from "../../../../../../../../services/cloudchipr.api";
import { useDynamicFiltersContext } from "../../../DynamicFiltersProvider";
import { findOptionByProviderAndKey } from "../../../../../filters-tree/utils/helpers/findOptionByProviderAndKey";

interface FilterOperatorSelectProps {
  selectedOperator: FilterOperatorType;
  filterType: ResourceExplorerDynamicFilterItemType;
  filterProvider: ResourceExplorerFilterProvider;
  onChange(operator: FilterOperatorType): void;
}

export const FilterOperatorSelect: FC<FilterOperatorSelectProps> = ({
  filterType,
  onChange,
  selectedOperator,
  filterProvider,
}) => {
  const { possibleFilters } = useDynamicFiltersContext();

  const currentFilterOperators = findOptionByProviderAndKey(
    possibleFilters,
    filterProvider,
    filterType,
  )?.operators;

  const renderValue = useCallback(
    (value: any) => {
      return currentFilterOperators?.find((operator) => operator.key === value)
        ?.label;
    },
    [currentFilterOperators],
  );

  const changeHandler = useCallback(
    (e: SelectChangeEvent) => {
      onChange(e.target.value as FilterOperatorType);
    },
    [onChange],
  );

  return (
    <FormControl size="xsmall" fullWidth>
      <InputLabel>Operator</InputLabel>

      <Select
        name="operator"
        value={selectedOperator}
        label="Operator"
        onChange={changeHandler}
        renderValue={renderValue}
      >
        {currentFilterOperators?.map(({ key, label }) => (
          <MenuItem key={key} value={key}>
            <ListItemText>{label}</ListItemText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
