import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { savingsPlansCoverageDataSelector } from "./savingsPlansCoverageDataSelector";

export const savingsPlansCoverageDataTotalsSelector = createDraftSafeSelector(
  [savingsPlansCoverageDataSelector],
  (data) => {
    return data?.reduce(
      (acc, item) => {
        acc.netSavings += item.net_savings ?? 0;
        acc.coveredUsage += item.covered_usage ?? 0;
        acc.unCoveredUsage += item.uncovered_usage ?? 0;
        acc.unCoveredUsage += item.uncovered_usage ?? 0;

        return acc;
      },
      { netSavings: 0, coveredUsage: 0, unCoveredUsage: 0 },
    );
  },
);
