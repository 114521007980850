import { v4 as uuid } from "uuid";
import { FilterTreeNode } from "../../../../../services/cloudchipr.api";
import {
  FilterItemNodeWithId,
  FilterTreeNodeWithId,
} from "../../../common/filters-tree/utils/types/common";
import { isTreeNodeFilterGroup } from "../../../common/filters-tree/utils/helpers/isTreeNodeFilterGroup";

export const addIdsToCategoryFilterTreeItems = (
  treeNode: FilterTreeNode,
): FilterTreeNodeWithId => {
  if (!isTreeNodeFilterGroup(treeNode)) {
    return { ...treeNode, id: uuid() } as FilterItemNodeWithId;
  }

  return {
    ...treeNode,
    items: treeNode.items.map(addIdsToCategoryFilterTreeItems),
  };
};
