import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { CommitmentsPageHeaderMetadataCard } from "../../../common/components/CommitmentsPageHeaderMetadataCard";
import { useAppSelector } from "../../../../../../store/hooks";
import { money } from "../../../../../../utils/numeral/money";
import { reservationsNetSavingsSelector } from "../../../../../../store/commitments/selectors/reservations/data/totals/reservationsNetSavingsSelector";
import { reservationsCoverageAverageSelector } from "../../../../../../store/commitments/selectors/reservations/data/totals/reservationsCoverageAverageSelector";
import { reservationsForGranularLoadingSelector } from "../../../../../../store/commitments/selectors/reservations/data/loading/reservationsForGranularLoadingSelector";
import { reservationsGranularDataUncoveredUsageSelector } from "../../../../../../store/commitments/selectors/reservations/data/granular/totals/reservationsGranularDataUncoveredUsageSelector";
import { organisationQuarterStartMonthSelector } from "../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { commitmentsDatesSelector } from "../../../../../../store/commitments/selectors/common/view-options/commitmentsDatesSelector";
import { generateFromToFromDates } from "../../../utilization-and-coverage/utils/helpers/generateFromToFromDates";

export const ReservationsMetadata: FC = () => {
  const netSavings = useAppSelector(reservationsNetSavingsSelector);
  const coverage = useAppSelector(reservationsCoverageAverageSelector);
  const loading = useAppSelector(reservationsForGranularLoadingSelector);
  const uncoveredUsage = useAppSelector(
    reservationsGranularDataUncoveredUsageSelector,
  );

  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const dates = useAppSelector(commitmentsDatesSelector);
  const datesFromTo = useMemo(() => {
    return generateFromToFromDates(dates, quarterStartMonth);
  }, [dates, quarterStartMonth]);

  return (
    <Stack
      gap={1}
      direction="row"
      flexWrap="wrap"
      justifyContent="space-between"
    >
      <CommitmentsPageHeaderMetadataCard
        primary="Not Covered(On-Demand)"
        loading={loading}
        value={money(uncoveredUsage)}
        tooltip={`Eligible On-Demand spend not covered by Reserved Instances for date range ${datesFromTo}.`}
      />

      <CommitmentsPageHeaderMetadataCard
        primary="Coverage AVG"
        loading={loading}
        value={coverage !== null ? `${coverage}%` : "N/A"}
        tooltip={`Average percentage of AWS usage covered by Reserved Instances ${datesFromTo}.`}
      />

      <CommitmentsPageHeaderMetadataCard
        primary="Net Savings"
        loading={loading}
        value={money(netSavings)}
        tooltip={`Total net savings from commitments for the date range ${datesFromTo}. Calculated by comparing the usage cost covered by Reserved Instances to the public On-Demand cost.`}
      />
    </Stack>
  );
};
