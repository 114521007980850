import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLargestCostChangesWidgetDataBySetupThunk } from "../data-get/getLargestCostChangesWidgetDataBySetupThunk";
import { cloudChiprApi } from "../../../../../../services/cloudchipr.api";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { RootState } from "../../../../../store";
import { largestCostChangesDefaultData } from "../../../../utils/constants/largestCostChangesDefaultData";
import { largestCostChangesSetupSelector } from "../../../../selectors/setups/larges-cost-changes/largestCostChangesSetupSelector";

export const largestCostChangesREViewChangeThunk = createAsyncThunk(
  "dashboards/largestCostChangesREViewChange",
  async (viewId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const setup = largestCostChangesSetupSelector(state);

    if (!setup) {
      return;
    }

    dispatch(setWidgetSetup({ ...setup, viewId }));

    const {
      getUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId,
    } = cloudChiprApi.endpoints;

    const response = await dispatch(
      getUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId.initiate(
        { resourceExplorerViewId: viewId },
      ),
    ).unwrap();

    if (!response) {
      return;
    }

    const { data } = response;

    const to = data?.to_date;
    const from = data?.from_date;
    const label = data?.date_label;

    dispatch(
      setWidgetSetup({
        ...(setup ?? largestCostChangesDefaultData),
        grouping: data.grouping === "resource" ? "service" : data.grouping,
        group_values: data.group_values,
        viewId: viewId,
        date: {
          label,
          to,
          from,
        },
      }),
    );
    dispatch(getLargestCostChangesWidgetDataBySetupThunk());
  },
);
