import { largesCostChangesSetupWidgetDataResponseSelector } from "./largesCostChangesSetupWidgetDataResponseSelector";
import { RootState } from "../../../../../store";
import { ResourceExplorerGridTotal } from "../../../../../../services/cloudchipr.api";

export const largesCostChangesSetupWidgetDataSelector = (
  state: RootState,
): ResourceExplorerGridTotal[] | undefined => {
  const response = largesCostChangesSetupWidgetDataResponseSelector(state);

  return response?.data?.total;
};
