import { FC, Fragment, useCallback, useState } from "react";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import { useFlag } from "@unleash/proxy-client-react";
import { ResourceExplorerV2ViewFoldersDeleteDialog } from "./dialogs/ResourceExplorerV2ViewFoldersDeleteDialog";
import { ResourceExplorerV2ViewFoldersRenameDialog } from "./dialogs/ResourceExplorerV2ViewFoldersRenameDialog";
import { ResourceExplorerV2FolderMoveAction } from "./move-action/ResourceExplorerV2FolderMoveAction";
import { navigationHierarchyItemNoDndKey } from "../../../sortable-tree/utils/constants";
import { NavigationItemsVisibilityType } from "../../../../../../store/navigation/utils/types";
import { ResourceExplorerCreateFolderOrItemDialogWrapper } from "../common/ResourceExplorerCreateFolderOrItemDialogWrapper";

interface ResourceExplorerViewWithFolderListItemActionsMenuProps {
  id: string;
  name: string;
  open: boolean;
  closeMenu(): void;
  anchor: HTMLElement | null;
  visibility: NavigationItemsVisibilityType;
}

export const ResourceExplorerV2ViewFolderListItemActionsMenu: FC<
  ResourceExplorerViewWithFolderListItemActionsMenuProps
> = ({ name, id, open, anchor, closeMenu, visibility }) => {
  const enableNavigationHierarchyVisibility = useFlag(
    "EnableNavigationHierarchyVisibility",
  );
  const [openDialogType, setOpenDialogType] = useState<
    "add-view" | "rename" | "delete"
  >();

  const renameClickHandler = useCallback(() => {
    setOpenDialogType("rename");
  }, []);

  const deleteClickHandler = useCallback(() => {
    setOpenDialogType("delete");
  }, []);

  const addViewClickHandler = useCallback(() => {
    setOpenDialogType("add-view");
  }, []);

  const closeDialog = useCallback(() => {
    setOpenDialogType(undefined);
    closeMenu();
  }, [closeMenu]);

  return (
    <Fragment>
      <Menu
        open={open}
        onClose={closeMenu}
        anchorEl={anchor}
        disablePortal={false}
        {...navigationHierarchyItemNoDndKey}
      >
        <MenuItem onClick={renameClickHandler} sx={menuItemSx}>
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" sx={iconSx} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">Rename</Typography>
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={addViewClickHandler} sx={menuItemSx}>
          <ListItemIcon>
            <AddIcon fontSize="small" sx={iconSx} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">Add view</Typography>
          </ListItemText>
        </MenuItem>

        {enableNavigationHierarchyVisibility && (
          <ResourceExplorerV2FolderMoveAction
            id={id}
            name={name}
            onClose={closeMenu}
            visibility={visibility}
          />
        )}

        <MenuItem onClick={deleteClickHandler} sx={menuItemSx}>
          <ListItemIcon>
            <DeleteOutlineIcon fontSize="small" sx={iconSx} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">Delete</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>

      {openDialogType === "delete" && (
        <ResourceExplorerV2ViewFoldersDeleteDialog
          open
          id={id}
          name={name}
          closeMenu={closeDialog}
          visibility={visibility}
        />
      )}

      {openDialogType === "rename" && (
        <ResourceExplorerV2ViewFoldersRenameDialog
          open
          id={id}
          name={name}
          closeMenu={closeDialog}
        />
      )}

      {openDialogType === "add-view" && (
        <ResourceExplorerCreateFolderOrItemDialogWrapper
          onClose={closeDialog}
          type="item"
          folderId={id}
          visibility={visibility}
        />
      )}
    </Fragment>
  );
};

const menuItemSx = {
  pl: 1,
  pr: 1.5,
};

const iconSx = {
  minWidth: 28,
};
