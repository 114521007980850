import { largestCostChangesWidgetDataSelector } from "./largestCostChangesWidgetDataSelector";
import { RootState } from "../../../../../store";

export const largestCostChangesWidgetDataExistSelector = (
  state: RootState,
  widgetId: string,
) => {
  const data = largestCostChangesWidgetDataSelector(state, widgetId);

  return !!data?.length;
};
