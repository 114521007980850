import { v4 as uuid } from "uuid";
import {
  CategoryFilter,
  FilterGroup,
  ResourceExplorerFilterProvider,
} from "../../../../../services/cloudchipr.api";
import {
  DynamicFiltersType,
  DynamicProviderFilter,
  DynamicProviderFilterGroup,
} from "../../../common/dynamic-filters/utils/types/common";
import { isDynamicProviderFilters } from "../../../common/dynamic-filters/utils/helpers/isDynamicProviderFilters";

export const convertCategoriesFiltersToDynamicFilters = (
  filters: CategoryFilter,
): DynamicFiltersType => {
  if (!filters.filters) {
    return {};
  }

  const operators = filters.filters.operators;
  const filterItems = filters.filters.filter_items;
  const filterGroups = filters.filters.filter_groups;
  let operatorIndex = 1;

  const addRecursive = (filterGroups: FilterGroup): DynamicProviderFilter[] => {
    return filterGroups.map((filterGroup) => {
      if (Array.isArray(filterGroup)) {
        const newFiltersGroup = {
          operator: operators[operatorIndex],
          filters: addRecursive(filterGroup),
        };

        operatorIndex++;

        return newFiltersGroup;
      } else {
        return { ...filterItems[filterGroup], id: uuid() };
      }
    });
  };

  const dynamicFilterGroups = filterGroups.map((providerFilterGroups) => {
    const operator = operators.at(operatorIndex++);

    if (!operator) {
      return null;
    }

    const dynamicFilterGroup: DynamicProviderFilterGroup = {
      operator,
      filters: [],
    };

    const smthWentWrong =
      !providerFilterGroups || typeof providerFilterGroups === "number";

    if (smthWentWrong) {
      return null;
    }

    providerFilterGroups.forEach((filterGroup) => {
      if (Array.isArray(filterGroup)) {
        const newFiltersGroup = {
          operator: operators[operatorIndex++],
          filters: addRecursive(filterGroup),
        };

        dynamicFilterGroup.filters.push(newFiltersGroup);
      } else {
        dynamicFilterGroup.filters.push({
          ...filterItems[filterGroup],
          id: uuid(),
        });
      }
    });

    return dynamicFilterGroup;
  });

  return generateDynamicProviderFilters(dynamicFilterGroups);
};

const generateDynamicProviderFilters = (
  dynamicFilterGroups: (DynamicProviderFilterGroup | null)[],
) => {
  return dynamicFilterGroups.reduce((acc, providerFilters) => {
    const provider = findProvider(providerFilters?.filters);

    if (!provider || !providerFilters) {
      return acc;
    }

    acc[provider] = providerFilters;

    return acc;
  }, {} as DynamicFiltersType);
};

const findProvider = (
  filters?: DynamicProviderFilter[],
): ResourceExplorerFilterProvider | undefined => {
  const first = filters?.at(0);

  if (!first) {
    return;
  }

  if (isDynamicProviderFilters(first)) {
    return findProvider(first.filters);
  }

  return first.filter_provider;
};
