import { FC, useMemo } from "react";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { AccountAddButton } from "../accounts/AccountAddButton";
import { PageHeader } from "../../../common/PageHeader";
import { getProviderName } from "../../../../../utils/helpers/providers/getProviderName";
import { ProviderLogo } from "../../../../common/provider-logo/ProviderLogo";
import { noOrgAccountOrgId } from "../../utils/helpers/noOrganizationConstants";
import { GcpAccountsHeaderActionButtons } from "../accounts/gcp-billing-export/GcpAccountsHeaderActionButtons";

interface AccountsHeaderProps {
  provider: ProviderType;
  accountsExist: boolean;
  orgId?: string;
}

export const AccountsHeader: FC<AccountsHeaderProps> = ({
  provider,
  accountsExist,
  orgId,
}) => {
  const breadcrumbs = useMemo(() => {
    const organizationId = orgId === noOrgAccountOrgId ? "" : orgId;

    if (organizationId) {
      return [
        {
          title: "Organizations",
          icon: <ProviderLogo provider={provider} />,
        },
        { title: organizationId },
      ];
    }

    return [
      {
        title: `${getProviderName(provider, { title: true })} ${getProviderName(
          provider,
          {
            plural: true,
            capitalize: true,
          },
        )}`,
        icon: <ProviderLogo provider={provider} />,
      },
    ];
  }, [provider, orgId]);

  const actions = useMemo(() => {
    if (!accountsExist) {
      return;
    }
    if (provider === "gcp") {
      return <GcpAccountsHeaderActionButtons />;
    }
    return <AccountAddButton provider={provider} />;
  }, [accountsExist, provider]);

  return <PageHeader breadcrumbs={breadcrumbs} actions={actions} />;
};
