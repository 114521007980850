import { FC, useCallback } from "react";
import { useToggle } from "rooks";
import { Card, CircularProgress, Collapse, Stack } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { CategoryCollapseHeader } from "./CategoryCollapseHeader";
import { DynamicFilters } from "../../../../../../common/dynamic-filters/components/DynamicFilters";
import { DimensionCategory } from "../../../../../../../../store/dimensions/utils/types/common";
import { DynamicFiltersType } from "../../../../../../common/dynamic-filters/utils/types/common";
import { useAppDispatch } from "../../../../../../../../store/hooks";
import {
  setCategoryFilters,
  setCategoryFilterTree,
} from "../../../../../../../../store/dimensions/dimensionsSlice";
import { useGetUsersMeOrganisationsCurrentResourceExplorerPossibleFiltersQuery } from "../../../../../../../../services/cloudchipr.api";
import { FilterTree } from "../../../../../../common/filters-tree/components/FilterTree";
import { FilterGroupNodeWithId } from "../../../../../../common/filters-tree/utils/types/common";

interface CategoryItemProps {
  category: DimensionCategory;
  deleteDisabled?: boolean;
}

export const CategoryItem: FC<CategoryItemProps> = ({
  category,
  deleteDisabled,
}) => {
  const { data, isLoading } =
    useGetUsersMeOrganisationsCurrentResourceExplorerPossibleFiltersQuery({});
  const dispatch = useAppDispatch();
  const [collapsed, toggleCollapsed] = useToggle();
  const enableTreeFilters = useFlag("EnableTreeFilters");

  const filtersChangeHandler = useCallback(
    (filters: DynamicFiltersType) => {
      dispatch(setCategoryFilters({ id: category.id, filters }));
    },
    [dispatch, category.id],
  );

  const filterTreeChangeHandler = useCallback(
    (tree: FilterGroupNodeWithId) => {
      dispatch(setCategoryFilterTree({ id: category.id, tree }));
    },
    [dispatch, category.id],
  );

  const dynamicFilters = category.filters ? (
    <DynamicFilters
      possibleFilters={data ?? []}
      filters={category.filters}
      setFilters={filtersChangeHandler}
    />
  ) : null;

  const treeFilters = enableTreeFilters ? (
    <FilterTree
      possibleFilters={data ?? []}
      filterTree={category.filter_tree}
      setFilters={filterTreeChangeHandler}
    />
  ) : null;

  const filtersComponent = enableTreeFilters ? treeFilters : dynamicFilters;

  return (
    <Card variant="outlined">
      <CategoryCollapseHeader
        title="Filters"
        collapsed={collapsed}
        category={category}
        deleteDisabled={!!deleteDisabled}
        toggleCollapsed={toggleCollapsed}
      />

      <Collapse in={!collapsed}>
        <Stack p={1} pt={0}>
          {isLoading ? (
            <Stack alignItems="center" padding={2}>
              <CircularProgress />
            </Stack>
          ) : (
            filtersComponent
          )}
        </Stack>
      </Collapse>
    </Card>
  );
};
