import { FC, useCallback, useMemo } from "react";
import { CellContext } from "@tanstack/react-table";
import { useFlag } from "@unleash/proxy-client-react";
import { FiltersCountButtonCell } from "../common/FiltersCountButtonCell";
import { generateDimensionCategoriesFromCategoriesWithCosts } from "../../../utils/helpers/generateDimensionCategoriesFromCategoriesWithCosts";
import { DimensionWithCosts } from "../../../../../../services/cloudchipr.api";
import { setDimensionsState } from "../../../../../../store/dimensions/dimensionsSlice";
import { useAppDispatch } from "../../../../../../store/hooks";
import { calculateFilterTreeFiltersCount } from "../../../utils/helpers/calculateFilterTreeFiltersCount";

export const DimensionFiltersCount: FC<
  CellContext<DimensionWithCosts, any>
> = ({ row }) => {
  const enableTreeFilters = useFlag("EnableTreeFilters");
  const dispatch = useAppDispatch();
  const dimension = row.original;

  const count = useMemo(() => {
    if (enableTreeFilters) {
      return dimension.categories.reduce((sum, category) => {
        return (
          sum + calculateFilterTreeFiltersCount(category.filters.filter_tree)
        );
      }, 0);
    }

    return dimension.categories.reduce((sum, category) => {
      return sum + (category.filters.filters?.filter_items?.length ?? 0);
    }, 0);
  }, [dimension.categories, enableTreeFilters]);

  const buttonClickHandler = useCallback(() => {
    dispatch(
      setDimensionsState({
        drawerMode: "dimensionEdit",
        dimensionId: dimension.id,
        dimensionName: dimension.name,
        categories: generateDimensionCategoriesFromCategoriesWithCosts(
          dimension.categories,
        ),
      }),
    );
  }, [dispatch, dimension]);

  return (
    <FiltersCountButtonCell
      count={count}
      dimensionId={dimension.id}
      onClick={buttonClickHandler}
    />
  );
};
