import { FC, useCallback, useMemo } from "react";
import { PopoverProps, Skeleton } from "@mui/material";
import { DropdownHeader } from "./DropdownHeader";
import { LiveUsageMgmtSelectedAccountNavigationTriggerComponent } from "./LiveUsageMgmtSelectedAccountNavigationTriggerComponent";
import { isEqualArrays } from "./utils/helpers/isEqualArrays";
import { AccountOptionLabel } from "../../../../../../common/account-select/components/AccountOptionLabel";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { SIDEBAR_WIDTH } from "../../../../../../../utils/constants/style-sizes";
import { DropdownSelect } from "../../../../../../common/select/dropdown-select/DropdownSelect";
import { accountOptionFilterFn } from "../../../../../../common/account-select/utils/helpers/accountOptionFilterFn";
import { liveUsageMgmtAccountIdsSelector } from "../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAccountIdsSelector";
import { liveUsageMgmtProviderSelector } from "../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import { liveUsageMgmtAccountsSelectOptionsSelector } from "../../../../../../../store/live-usage-mgmt/selectors/accounts-data/liveUsageMgmtAccountsSelectOptionsSelector";
import { generateAccountIdsQueryParams } from "../../../../utils/helpers/generateAccountIdsQueryParams";
import { liveUsageMgmtAccountsChangeThunk } from "../../../../../../../store/live-usage-mgmt/thunks/liveUsageMgmtAccountsChangeThunk";

export const LiveUsageMgmtSelectedAccountNavigation: FC = () => {
  const appliedAccountIds = useAppSelector(liveUsageMgmtAccountIdsSelector);

  const dispatch = useAppDispatch();
  const groupedAllAccounts = useAppSelector(
    liveUsageMgmtAccountsSelectOptionsSelector,
  );
  const provider = useAppSelector(liveUsageMgmtProviderSelector);

  const options = useMemo(() => {
    return groupedAllAccounts?.map((option) => {
      let listItemHeight = 60;

      if (
        option.providerAccountId &&
        !option.label.includes(option.providerAccountId)
      ) {
        listItemHeight += 20;
      }

      return {
        value: option.value,
        rawValue: option,
        listItemHeight,
        groupName: option.orgId,
        label: (
          <AccountOptionLabel
            option={{ ...option, orgId: option.orgId }}
            showOrgId
            showStatus={option.status === "deactivated"}
          />
        ),
      };
    });
  }, [groupedAllAccounts]);

  const accountChangeHandler = useCallback(
    async (accountIds: string[]) => {
      if (
        !provider ||
        !options ||
        isEqualArrays(appliedAccountIds ?? [], accountIds)
      ) {
        return;
      }

      if (!accountIds.length) {
        const allAccountIds =
          options?.reduce((result, item) => {
            result.push(item.value);
            return result;
          }, [] as string[]) ?? [];

        window.history.replaceState(
          {},
          "",
          `/${provider}/live-usage${generateAccountIdsQueryParams(allAccountIds)}`,
        );

        dispatch(
          liveUsageMgmtAccountsChangeThunk({
            accountIds: allAccountIds,
            provider,
          }),
        );
      } else {
        window.history.replaceState(
          {},
          "",
          `/${provider}/live-usage${generateAccountIdsQueryParams(accountIds)}`,
        );
        dispatch(
          liveUsageMgmtAccountsChangeThunk({
            accountIds,
            provider,
          }),
        );
      }
    },
    [provider, dispatch, options, appliedAccountIds],
  );

  if (!appliedAccountIds || !provider) {
    return <Skeleton variant="text" height={100} width={SIDEBAR_WIDTH - 20} />;
  }

  return (
    <DropdownSelect
      label="Accounts"
      listWidth={400}
      options={options ?? []}
      filterFn={accountOptionFilterFn}
      PopoverProps={popoverProps}
      submitHandlerOnClose={accountChangeHandler}
      DropdownHeader={DropdownHeader}
      values={appliedAccountIds ?? []}
      TriggerComponent={LiveUsageMgmtSelectedAccountNavigationTriggerComponent}
      disableEmptySelectionList
    />
  );
};

const popoverProps = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
} as PopoverProps;
