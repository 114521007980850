import { FC, useMemo } from "react";
import {
  ResourceExplorerDynamicFilterItemType,
  ResourceExplorerFilterProvider,
  useGetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesQuery as useValuesQuery,
} from "../../../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";
import { DropdownSelectOption } from "../../../../../../../../common/select/dropdown-select/utils/types/types";
import { DropdownSelect } from "../../../../../../../../common/select/dropdown-select/DropdownSelect";
import { FilterValueSelectionTrigger } from "../FilterValueSelectionTrigger";
import { FilterItemNodeWithId } from "../../../../../utils/types/common";

interface FilterValueAutocompleteProps {
  label?: string;
  singleSelect?: boolean;
  filterType: ResourceExplorerDynamicFilterItemType;
  filterValue: string[];
  provider: ResourceExplorerFilterProvider;
  onChange(value: FilterItemNodeWithId["value"]): void;
}

export const FilterValueAutocomplete: FC<FilterValueAutocompleteProps> = ({
  provider,
  filterType,
  onChange,
  filterValue,
  singleSelect,
  label,
}) => {
  const { data, isLoading } = useValuesQuery({
    filterProvider: provider,
    filterType: filterType,
  });

  const renderOptions = useMemo(() => {
    return data?.map((option) => ({
      rawValue: option,
      value: option.value,
      label: (
        <TypographyWithTooltip
          title={option.title ?? option.value}
          fontSize="inherit"
        />
      ),
    }));
  }, [data]);

  return (
    <DropdownSelect
      singleSelect={singleSelect}
      label={label ?? "Value"}
      options={renderOptions ?? []}
      wrapperVariant="popover"
      submitHandlerOnClose={onChange}
      filterFn={filterFn}
      disabled={isLoading}
      values={filterValue ?? []}
      optionsLoading={isLoading}
      TriggerComponent={FilterValueSelectionTrigger}
      PopoverProps={{ extendAnchorWidth: true }}
    />
  );
};

const filterFn = (option: DropdownSelectOption, keyword: string) => {
  const aim = `${option.rawValue.value} ${option.rawValue.title}`.toLowerCase();

  return aim.includes(keyword.toLowerCase().trim());
};
