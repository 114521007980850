import { LinearProgress, Stack } from "@mui/material";
import { Fragment } from "react";
import { RecommendationsCsvDataDownloader } from "./RecommendationsCsvDataDownloader";
import { UtilizationAndCoverageDataViewTabs } from "../../data-grid/components/UtilizationAndCoverageDataViewTabs";
import { GlobalFilter } from "../../../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { RenderToolbar } from "../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { CommitmentsRecommendationsFilters } from "../filters/CommitmentsRecommendationsFilters";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { commitmentsRecommendationsLoadingSelector } from "../../../../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsLoadingSelector";
import { ColumnsShowHideSwitcher } from "../../../../../common/components/data-grid/ColumnsShowHideSwitcher";

export const RecommendationsDataGridToolbar: RenderToolbar<unknown> = ({
  setGlobalFilter,
  globalFilter,
  table,
}) => {
  const loading = useAppSelector(commitmentsRecommendationsLoadingSelector);

  return (
    <Fragment>
      <Stack pb={2}>
        <Stack
          pb={2}
          direction="row"
          alignItems="start"
          justifyContent="space-between"
        >
          <UtilizationAndCoverageDataViewTabs />

          <Stack alignItems="center" direction="row" spacing={2}>
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              size="small"
            />

            <RecommendationsCsvDataDownloader />

            <ColumnsShowHideSwitcher table={table} />
          </Stack>
        </Stack>

        <CommitmentsRecommendationsFilters />
      </Stack>

      {loading && <LinearProgress />}
    </Fragment>
  );
};
