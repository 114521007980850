import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../store";
import { costBreakdownWidgetByIdSelector } from "../../../selectors/widgets/cost-breakdown/costBreakdownWidgetByIdSelector";
import { getWidgetDataByViewIdThunk } from "../common/getWidgetDataByViewIdThunk";
import { previousPeriodVisualizationTypes } from "../utils/constants";
import { WidgetViewType } from "../../../utils/types/types";

export const fetchCostBreakdownWidgetDataByWidgetIdThunk = createAsyncThunk(
  "dashboards/fetchCostBreakdownWidgetDataByWidgetIdThunk",
  async (widgetId: string, { dispatch, getState }) => {
    const state = getState() as RootState;

    const widget = costBreakdownWidgetByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }

    await dispatch(
      getWidgetDataByViewIdThunk({
        widgetId: widgetId,
        widgetType: "cost_breakdown",
        resourceExplorerViewId: widget.view_id,
        dateTo: widget.date_to,
        dateFrom: widget.date_from,
        frequency: widget.date_granularity,
        grouping: widget.grouping,
        groupValues: widget.group_values,
        dateLabel: widget.date_label,
        loadDetailedPreviousPeriodData: previousPeriodVisualizationTypes.has(
          widget?.chart_type as WidgetViewType,
        ),
        monthlyForecast: widget.forecast_option === "month",
        quarterlyForecast: widget.forecast_option === "quarter",
      }),
    );
  },
);
