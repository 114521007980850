import { FC } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { CommitmentsDateRange } from "../../common/components/options/date-range/CommitmentsDateRange";
import { useAppSelector } from "../../../../../store/hooks";
import { savingsPlansForTableLoadingSelector } from "../../../../../store/commitments/selectors/coverage-and-utilization/data/loading/savingsPlansForTableLoadingSelector";
import { PageHeader } from "../../../common/PageHeader";
import { savingsPlansForGranularLoadingSelector } from "../../../../../store/commitments/selectors/coverage-and-utilization/data/loading/savingsPlansForGranularLoadingSelector";
import { reservationsForTableLoadingSelector } from "../../../../../store/commitments/selectors/reservations/data/loading/reservationsForTableLoadingSelector";
import { reservationsForGranularLoadingSelector } from "../../../../../store/commitments/selectors/reservations/data/loading/reservationsForGranularLoadingSelector";
import { CommitmentTypeTabs } from "../../common/components/CommitmentTypeTabs";

export const UtilizationAndCoveragePageHeader: FC = () => {
  const savingsPlansTableDataLoading = useAppSelector(
    savingsPlansForTableLoadingSelector,
  );
  const reservationsTableDataLoading = useAppSelector(
    reservationsForTableLoadingSelector,
  );
  const savingsPlansGranularDataLoading = useAppSelector(
    savingsPlansForGranularLoadingSelector,
  );
  const reservationsGranularDataLoading = useAppSelector(
    reservationsForGranularLoadingSelector,
  );

  const enableReservedInstances = useFlag("EnableReservedInstances");

  const loading =
    savingsPlansTableDataLoading ||
    reservationsTableDataLoading ||
    savingsPlansGranularDataLoading ||
    reservationsGranularDataLoading;

  return (
    <PageHeader
      sticky
      loading={loading}
      actions={<CommitmentsDateRange />}
      extra={enableReservedInstances ? <CommitmentTypeTabs /> : undefined}
      title={enableReservedInstances ? "Commitments" : "Utilization & Coverage"}
    />
  );
};
