import { FC, useCallback } from "react";
import { Menu, PopoverOrigin, Stack } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { useNavigate, useParams } from "react-router-dom";
import { ResourceExplorerV2ViewMoveActionMenuSection } from "./ResourceExplorerV2ViewMoveActionMenuSection";
import { navigationHierarchyItemNoDndKey } from "../../../../sortable-tree/utils/constants";
import { NavigationItemsVisibilityType } from "../../../../../../../store/navigation/utils/types";
import { updateResourceExplorerVisibilityHierarchyThunk } from "../../../../../../../store/resource-explorer/thunks/resource-explorer-hierarchy-visibility/updateResourceExplorerVisibilityHierarchyThunk";
import { updateResourceExplorerHierarchyThunk } from "../../../../../../../store/resource-explorer/thunks/resource-explorer-hierarchy/updateResourceExplorerHierarchyThunk";
import { useAppDispatch } from "../../../../../../../store/hooks";

interface ResourceExplorerV2ViewMoveActionMenuProps {
  id: string;
  name: string;
  folderId?: string;
  anchor: HTMLElement | null;
  open: boolean;
  onClose(): void;
  visibility: NavigationItemsVisibilityType;
}

export const ResourceExplorerV2ViewMoveActionMenu: FC<
  ResourceExplorerV2ViewMoveActionMenuProps
> = ({ id, name, folderId, anchor, onClose, open, visibility }) => {
  const { viewId: currentViewId = "" } = useParams<{
    viewId: string;
  }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const enableNavigationHierarchyVisibility = useFlag(
    "EnableNavigationHierarchyVisibility",
  );

  const moveToFolder = useCallback(
    async (visibility: NavigationItemsVisibilityType, folderId?: string) => {
      if (enableNavigationHierarchyVisibility) {
        const viewId = await dispatch(
          updateResourceExplorerVisibilityHierarchyThunk({
            id,
            folderId,
            visibility,
            type: "view",
          }),
        ).unwrap();

        if (viewId !== id && id === currentViewId) {
          navigate(`/resource-explorer/${viewId}`);
        }
      } else {
        await dispatch(
          updateResourceExplorerHierarchyThunk({
            folderId,
            viewId: id,
          }),
        );
      }

      onClose();
    },
    [
      id,
      dispatch,
      onClose,
      enableNavigationHierarchyVisibility,
      navigate,
      currentViewId,
    ],
  );

  const moveToRoot = useCallback(
    async (visibility: NavigationItemsVisibilityType) => {
      if (enableNavigationHierarchyVisibility) {
        const viewId = await dispatch(
          updateResourceExplorerVisibilityHierarchyThunk({
            id,
            index: 1,
            visibility,
            type: "view",
          }),
        ).unwrap();

        if (viewId !== id && id === currentViewId) {
          navigate(`/resource-explorer/${viewId}`);
        }
      } else {
        await dispatch(
          updateResourceExplorerHierarchyThunk({
            viewId: id,
            index: 1,
          }),
        );
      }

      onClose();
    },
    [
      id,
      dispatch,
      onClose,
      enableNavigationHierarchyVisibility,
      navigate,
      currentViewId,
    ],
  );

  return (
    <Menu
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      open={open}
      onClose={onClose}
      anchorEl={anchor}
      slotProps={slotProps}
      {...navigationHierarchyItemNoDndKey}
    >
      {enableNavigationHierarchyVisibility ? (
        <Stack spacing={1}>
          <ResourceExplorerV2ViewMoveActionMenuSection
            folderId={folderId}
            visibility="visible_to_everyone"
            currentViewName={name}
            currentVisibility={visibility}
            moveToFolder={moveToFolder}
            moveToRoot={moveToRoot}
          />
          <ResourceExplorerV2ViewMoveActionMenuSection
            folderId={folderId}
            visibility="visible_only_to_me"
            currentViewName={name}
            currentVisibility={visibility}
            moveToFolder={moveToFolder}
            moveToRoot={moveToRoot}
          />
        </Stack>
      ) : (
        <ResourceExplorerV2ViewMoveActionMenuSection
          hideTitle
          folderId={folderId}
          visibility={visibility}
          currentViewName={name}
          currentVisibility={visibility}
          moveToFolder={moveToFolder}
          moveToRoot={moveToRoot}
        />
      )}
    </Menu>
  );
};

const slotProps = {
  paper: {
    sx: {
      minWidth: 200,
      maxWidth: 400,
      maxHeight: 400,
    },
  },
};

const anchorOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};
const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};
