import { FC, useCallback, useEffect, useMemo } from "react";
import { Table } from "@tanstack/react-table";
import { RecommendationsDataGridToolbar } from "./data-grid/RecommendationsDataGridToolbar";
import { CommitmentsRecommendationsDataGridFooter } from "./CommitmentsRecommendationsDataGridFooter";
import { DataGrid } from "../../../../../../../storybook/data-grid/DataGrid";
import { getCommitmentsTableStyles } from "../../../../common/utils/helpers/grid/getCommitmentsTableStyles";
import { ToolbarConfig } from "../../../../../../../storybook/data-grid/utils/types/prop-types";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { commitmentsRecommendationsFilteredDataSelector } from "../../../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsFilteredDataSelector";
import { connectedOrganizationExistsSelector } from "../../../../../../../store/commitments/selectors/organisations/connectedOrganizationExistsSelector";
import { commitmentsRecommendationsSavingsPlanTypeSelector } from "../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsSavingsPlanTypeSelector";
import { commitmentsRecommendationsLoadingSelector } from "../../../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsLoadingSelector";
import { setCommitmentsRecommendationsFilters } from "../../../../../../../store/commitments/commitmentsSlice";
import { getCommitmentsRecommendationsThunk } from "../../../../../../../store/commitments/thunks/recommendations/getCommitmentsRecommendationsThunk";
import { commitmentsCurrentOrgIdSelector } from "../../../../../../../store/commitments/selectors/common/commitmentsCurrentOrgIdSelector";
import { filterRecommendationsColumns } from "../../../utils/helpers/filterRecommendationsColumns";
import { setRecommendationsInitialFiltersThunk } from "../../../../../../../store/commitments/thunks/recommendations/setRecommendationsInitialFiltersThunk";
import { useCommitmentsColumnsVisibility } from "../../../../common/utils/hooks/useColumnsVisibility.hook";

export const CommitmentsRecommendationsDataGrid: FC = () => {
  const dispatch = useAppDispatch();

  const data = useAppSelector(commitmentsRecommendationsFilteredDataSelector);
  const orgExists = useAppSelector(connectedOrganizationExistsSelector);
  const orgId = useAppSelector(commitmentsCurrentOrgIdSelector);
  const loading = useAppSelector(commitmentsRecommendationsLoadingSelector);
  const savingsPlanTypes = useAppSelector(
    commitmentsRecommendationsSavingsPlanTypeSelector,
  );
  const empty = !loading && !data?.length;

  const { visibility, setVisibility } = useCommitmentsColumnsVisibility(
    "savings_plan",
    "recommendations",
  );

  const columns = useMemo(() => {
    return filterRecommendationsColumns(orgExists, savingsPlanTypes);
  }, [orgExists, savingsPlanTypes]);

  const renderPagination = useCallback(
    (table: Table<any>) => {
      const rows = table.getRowModel().rows;

      if (empty) {
        return <CommitmentsRecommendationsDataGridFooter option="emptyData" />;
      }

      if (rows.length || loading) {
        return null;
      }

      return (
        <CommitmentsRecommendationsDataGridFooter option="noFilteredData" />
      );
    },
    [empty, loading],
  );

  useEffect(() => {
    dispatch(setCommitmentsRecommendationsFilters(null));
    dispatch(getCommitmentsRecommendationsThunk());
    dispatch(setRecommendationsInitialFiltersThunk());
  }, [dispatch, orgId]);

  return (
    <DataGrid
      columnVisibility={{
        initialVisibility: visibility,
        onColumnsVisibilityChange: setVisibility,
      }}
      columnSorting
      columnResizeMode="onEnd"
      enableStickyColumns
      data={data ?? []}
      columns={columns}
      toolbar={toolbar}
      styles={getCommitmentsTableStyles(empty)}
      pagination={{
        renderPagination,
      }}
    />
  );
};

const toolbar: ToolbarConfig = {
  renderToolbar: (props) => <RecommendationsDataGridToolbar {...props} />,
};
