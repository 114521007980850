import { FC, useMemo, useState } from "react";
import { Divider, Stack } from "@mui/material";
import { PopoverHeader } from "./PopoverHeader";
import { GroupSelect } from "./GroupSelect";
import { FilterItemSelect } from "./FilterItemSelect";
import { NewFilterAddFn } from "./FilterItemSelectMenuItem";
import { ResourceExplorerFilterProvider } from "../../../../../../../services/cloudchipr.api";
import { useFilterTreeContext } from "../../FilterTreeProvider";
import { FiltersGroupType } from "../../../utils/types/common";

interface FilterSelectPopoverContentProps {
  onSelect: NewFilterAddFn;
  filterProvider?: ResourceExplorerFilterProvider;
}

export const FilterSelectPopoverContent: FC<
  FilterSelectPopoverContentProps
> = ({ onSelect, filterProvider }) => {
  const [keyword, setKeyword] = useState("");
  const [group, setGroup] = useState<FiltersGroupType>(filterProvider ?? "all");

  const { possibleFilters } = useFilterTreeContext();

  const filteredPossibleFilters = useMemo(() => {
    return possibleFilters.filter((filter) => {
      const target = `${filter.label} ${filter.filter_provider}`.toLowerCase();

      return target.includes(keyword.trim().toLowerCase());
    });
  }, [possibleFilters, keyword]);

  const groups = useMemo(() => {
    const groups = filteredPossibleFilters
      .filter((filter) => filter.filter_provider !== "global")
      .reduce(
        (acc, filter) => {
          acc[filter.filter_provider] = (acc[filter.filter_provider] ?? 0) + 1;

          return acc;
        },
        {} as Record<ResourceExplorerFilterProvider, number>,
      );

    return {
      all: filteredPossibleFilters.length,
      ...groups,
    };
  }, [filteredPossibleFilters]);

  const filtersByGroup = useMemo(() => {
    if (group === "all") {
      return filteredPossibleFilters;
    }

    return filteredPossibleFilters.filter(
      ({ filter_provider }) => filter_provider === group,
    );
  }, [filteredPossibleFilters, group]);

  return (
    <Stack>
      <PopoverHeader keyword={keyword} setKeyword={setKeyword} />

      <Divider />

      <Stack direction="row" flex={1}>
        {!filterProvider && (
          <GroupSelect
            groupOptions={groups}
            group={group}
            setGroup={setGroup}
          />
        )}

        <Divider orientation="vertical" flexItem />

        <FilterItemSelect filters={filtersByGroup} onSelect={onSelect} />
      </Stack>
    </Stack>
  );
};
