import { FC } from "react";
import { useAppSelector } from "../../../../../../../store/hooks";
import { DashboardNotFoundWidget } from "../../common/DashboardNotFoundWidget";
import { DashboardEmptyWidgetState } from "../../common/DashboardEmptyWidgetState";
import { largestCostChangesWidgetNotFoundSelector } from "../../../../../../../store/dashboards/selectors/widgets/largest-cost-changes/largestCostChangesWidgetNotFoundSelector";
import { largestCostChangesWidgetByIdSelector } from "../../../../../../../store/dashboards/selectors/widgets/largest-cost-changes/largestCostChangesWidgetByIdSelector";
import { LargestCostChangesWidgetBody } from "../../../adding-widget/widget-create/widget-setups/larges-cost-changes/content/components/LargestCostChangesWidgetBody";
import {
  LargestCostDate,
  LargestCostPrevDate,
} from "../../../adding-widget/widget-create/widget-setups/larges-cost-changes/utils/types/types";
import { largestCostChangesWidgetDataSelector } from "../../../../../../../store/dashboards/selectors/widgets/largest-cost-changes/widget-data/largestCostChangesWidgetDataSelector";
import { largestCostChangesWidgetDataLoadingSelector } from "../../../../../../../store/dashboards/selectors/widgets/largest-cost-changes/widget-data/largestCostChangesWidgetDataLoadingSelector";

interface LargestCostChangesWidgetContentProps {
  widgetId: string;
  viewName?: string;
  previousPeriodDate?: LargestCostPrevDate;
  date?: LargestCostDate;
}
export const LargestCostChangesWidgetContent: FC<
  LargestCostChangesWidgetContentProps
> = ({ widgetId, viewName, previousPeriodDate, date }) => {
  const widgetDataNotFound = useAppSelector((state) =>
    largestCostChangesWidgetNotFoundSelector(state, widgetId),
  );

  const widget = useAppSelector((state) =>
    largestCostChangesWidgetByIdSelector(state, widgetId),
  );

  const data = useAppSelector((state) =>
    largestCostChangesWidgetDataSelector(state, widgetId),
  );

  const loading = useAppSelector((state) =>
    largestCostChangesWidgetDataLoadingSelector(state, widgetId),
  );

  if (widgetDataNotFound) {
    return (
      <DashboardNotFoundWidget
        name={viewName ?? ""}
        label="resource explorer view"
      />
    );
  }

  if (!widget) {
    return <DashboardEmptyWidgetState />;
  }

  return (
    <LargestCostChangesWidgetBody
      data={data ?? []}
      loading={loading}
      previousPeriodDate={previousPeriodDate}
      date={date}
    />
  );
};
