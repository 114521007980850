import { FC, useCallback } from "react";
import { useEffectOnceWhen } from "rooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { ResourceExplorerGrouping } from "../../../../../../../../../services/cloudchipr.api";
import { GroupBySelector } from "../../../../../../../common/resource-explorer/group-by-selector/GroupBySelector";
import { getResourceExplorerPossibleFiltersByProvidersThunk } from "../../../../../../../../../store/common/thunks/resource-explorer/getResourceExplorerPossibleFiltersByProvidersThunk";
import { resourceExplorerDataGroupingAvailableTagsSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerDataGroupingAvailableTagsSelector";
import { largestCostChangesSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/larges-cost-changes/largestCostChangesSetupPropertyByKeySelector";
import { largestCostChangesSetupChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/largest-cost-changes/widget-change/largestCostChangesSetupChangeThunk";
import { selectedViewProvidersSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/common/selectedViewProvidersSelector";
import { selectedViewFilteredByDimensionsSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/common/selectedViewFilteredByDimensionsSelector";

interface LargestCostChangesGroupingSelectProps {
  disabled?: boolean;
}
export const LargestCostChangesGroupingSelect: FC<
  LargestCostChangesGroupingSelectProps
> = ({ disabled }) => {
  const dispatch = useAppDispatch();

  const groupBy = useAppSelector(
    largestCostChangesSetupPropertyByKeySelector("grouping"),
  );
  const groupValues = useAppSelector(
    largestCostChangesSetupPropertyByKeySelector("group_values"),
  );
  const availableTags = useAppSelector(
    resourceExplorerDataGroupingAvailableTagsSelector,
  );
  const providers = useAppSelector(selectedViewProvidersSelector);
  const filteredByDimensions = useAppSelector(
    selectedViewFilteredByDimensionsSelector,
  );

  const groupingChangeHandler = useCallback(
    (grouping: ResourceExplorerGrouping, groupValues?: string[]) => {
      dispatch(
        largestCostChangesSetupChangeThunk({
          grouping,
          group_values: groupValues,
        }),
      );
    },
    [dispatch],
  );

  useEffectOnceWhen(() => {
    dispatch(getResourceExplorerPossibleFiltersByProvidersThunk(providers));
  }, !!providers.length);

  return (
    <GroupBySelector
      value={groupBy}
      disabled={disabled}
      providers={providers}
      groupValues={groupValues ?? null}
      onGroupingChange={groupingChangeHandler}
      categoryGroupingAvailable={filteredByDimensions}
      groupValueOptions={availableTags ?? undefined}
    />
  );
};
