import { FC } from "react";
import { Stack, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { TypographyWithTooltip } from "../../../../../../../../../../common/TypographyWithTooltip";

interface CostAnomalyPreviewTableHeaderCellProps {
  title: string;
  description: string;
  tooltipTitle?: string;
}

export const CostAnomalyPreviewTableHeaderCell: FC<
  CostAnomalyPreviewTableHeaderCellProps
> = ({ title, description, tooltipTitle }) => {
  return (
    <Stack overflow="hidden">
      <Tooltip arrow placement="top" title={tooltipTitle}>
        <Typography variant="caption" fontWeight="medium">
          {title}
        </Typography>
      </Tooltip>
      <TypographyWithTooltip
        title={description}
        fontSize={10}
        color="text.secondary"
      />
    </Stack>
  );
};
