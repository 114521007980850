import { FC, useCallback, useMemo } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { WidgetWithREViewDisabledAction } from "./disabled-actions/WidgetWithREViewDisabledAction";
import { WidgetOptionCard } from "../../WidgetOptionCard";
import Cover from "../../../../utils/assets/images/widget-covers/cost_breakdown.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { isBillingAvailableSelector } from "../../../../../../../store/common/selectors/billing-status/isBillingAvailableSelector";
import { initiateCostBreakdownWidgetThunk } from "../../../../../../../store/dashboards/thunks/widgets/cost-breakdown/initiateCostBreakdownWidgetThunk";
import { defaultResourceExplorerViewIdSelector } from "../../../../../../../store/resource-explorer/selectors/all-resource-explorers/defaultResourceExplorerViewIdSelector";
import { initiateCostBreakdownV2WidgetThunk } from "../../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/initiateCostBreakdownV2WidgetThunk";

export const CostBreakdownCard: FC = () => {
  const dispatch = useAppDispatch();

  const enableCostBreakdownNewEndpoints = useFlag(
    "EnableCostBreakdownNewEndpoints",
  );
  const isResourceExplorerAvailable = useAppSelector(
    isBillingAvailableSelector,
  );

  const defaultViewId = useAppSelector(defaultResourceExplorerViewIdSelector);

  const clickHandler = useCallback(() => {
    if (enableCostBreakdownNewEndpoints) {
      dispatch(initiateCostBreakdownV2WidgetThunk());
    } else {
      dispatch(initiateCostBreakdownWidgetThunk());
    }
  }, [dispatch, enableCostBreakdownNewEndpoints]);

  const disabledAction = useMemo(() => {
    if (!isResourceExplorerAvailable) {
      return (
        <WidgetWithREViewDisabledAction
          viewId={defaultViewId}
          available={isResourceExplorerAvailable}
        />
      );
    }
  }, [isResourceExplorerAvailable, defaultViewId]);

  return (
    <WidgetOptionCard
      title="Cost breakdown"
      description="View costs by Accounts, Services, Regions, Product Families, and Cloud Providers."
      onClick={clickHandler}
      disabledAction={disabledAction}
    >
      <img src={Cover} alt="ConstAndUsageCard" />
    </WidgetOptionCard>
  );
};
