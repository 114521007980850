import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
  cloudChiprApi,
  DateDataPoint,
  DateLabelNullable,
  PostUsersMeOrganisationsCurrentWidgetsCostByCategoryApiArg,
} from "../../../../../../services/cloudchipr.api";
import { generateDateFromDateLabelV1 } from "../../../../../../components/common/date-range-picker/utils/helpers/generateDateFromDateLabelV1";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";
import { getFetchCostByCategoryWidgetDataThunkFixedCacheKey } from "../../../../utils/helpers/fixedCahcheKeys";

export const fetchCostByCategoryWidgetDataThunk = createAsyncThunk(
  "dashboards/fetchCostByCategoryWidgetData",
  async (
    {
      id,
      dateFrom,
      dateLabel,
      dateTo,
      frequency,
      categoryIds,
      displayFilteredResources,
    }: {
      id: string;
      dateLabel: DateLabelNullable;
      dateTo: string | null;
      dateFrom: string | null;
      categoryIds: string[];
      frequency: DateDataPoint;
      displayFilteredResources: boolean;
    },
    { dispatch },
  ) => {
    const { postUsersMeOrganisationsCurrentWidgetsCostByCategory } =
      cloudChiprApi.endpoints;

    const range = generateDateFromDateLabelV1(dateLabel);
    const to = formatDate(moment(range?.endDate));
    const from = formatDate(moment(range?.startDate));
    const date_to = dateTo ?? to;
    const date_from = dateFrom ?? from;

    const body: PostUsersMeOrganisationsCurrentWidgetsCostByCategoryApiArg["body"] =
      {
        date_to,
        date_from,
        category_ids: categoryIds,
        date_granularity: frequency,
        display_filtered_resources: displayFilteredResources,
      };

    await dispatch(
      postUsersMeOrganisationsCurrentWidgetsCostByCategory.initiate(
        { body },
        {
          fixedCacheKey: getFetchCostByCategoryWidgetDataThunkFixedCacheKey(id),
        },
      ),
    ).unwrap();
  },
);
