import { FC, useCallback, useMemo } from "react";
import {
  KeyValuePair,
  ResourceExplorerDynamicFilterItem,
  ResourceExplorerDynamicFilterItemType,
  ResourceExplorerFilterProvider,
  useGetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesQuery as useValuesQuery,
} from "../../../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";
import { DropdownSelectOption } from "../../../../../../../../common/select/dropdown-select/utils/types/types";
import { DropdownSelect } from "../../../../../../../../common/select/dropdown-select/DropdownSelect";
import { FilterValueSelectionTrigger } from "../../../../../../filters-tree/components/provider-dynamic-filters/filter-item/components/FilterValueSelectionTrigger";

interface FilterValueByKeyAutocompleteProps {
  value: KeyValuePair | null;
  filterType: ResourceExplorerDynamicFilterItemType;
  provider: ResourceExplorerFilterProvider;
  onChange(value: ResourceExplorerDynamicFilterItem["value"]): void;
}

export const FilterValueByKeyAutocomplete: FC<
  FilterValueByKeyAutocompleteProps
> = ({ provider, value, filterType, onChange }) => {
  const filterValueKey = value?.key;
  const filterValue = value?.value;

  const { data, isLoading } = useValuesQuery(
    {
      filterProvider: provider,
      filterType: filterType,
      key: filterValueKey,
    },
    { skip: !filterValueKey },
  );

  const changeHandler = useCallback(
    (value: string[]) => {
      if (!filterValueKey) {
        return;
      }

      onChange({ key: filterValueKey, value });
    },
    [onChange, filterValueKey],
  );

  const renderOptions = useMemo(() => {
    return data?.map((option) => ({
      rawValue: option,
      value: option.value,
      label: <TypographyWithTooltip title={option.title ?? option.value} />,
    }));
  }, [data]);

  const values = typeof filterValue === "string" ? [filterValue] : filterValue;

  return (
    <DropdownSelect
      listWidth={300}
      label="Value"
      options={renderOptions ?? []}
      wrapperVariant="popover"
      submitHandlerOnClose={changeHandler}
      filterFn={filterFn}
      disabled={!filterValueKey || isLoading}
      values={values ?? []}
      optionsLoading={isLoading}
      TriggerComponent={FilterValueSelectionTrigger}
      PopoverProps={{ extendAnchorWidth: true }}
    />
  );
};

const filterFn = (option: DropdownSelectOption, keyword: string) => {
  const aim = `${option.rawValue.value} ${option.rawValue.title}`.toLowerCase();

  return aim.includes(keyword.toLowerCase().trim());
};
