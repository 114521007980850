import { FC, useMemo } from "react";
import { Stack, TypographyProps } from "@mui/material";
import {
  commitmentsPaymentOptionLabels,
  commitmentsResourceTypeNames,
  commitmentsStateLabels,
} from "../../../../common/utils/constants/labels";
import { useAppSelector } from "../../../../../../../store/hooks";
import { TagCell } from "../../../../../../common/data-grid-cells/tag-cell/TagCell";
import { AccountCell } from "../../../../common/components/data-grid/cells/AccountCell";
import { DatesCell } from "../../../../common/components/data-grid/cells/DatesCell";
import { CommitmentsDrawerHeaderInfoCard } from "../../../../common/components/drawer/header/CommitmentsDrawerHeaderInfoCard";
import { selectedReservationDataSelector } from "../../../../../../../store/commitments/selectors/reservations/details-drawer/reserved-instance/selectedReservationDataSelector";
import { capitalizeString } from "../../../../../../../utils/helpers/capitalizeString";

export const ReservedInstanceDrawerContentHeaderInfoCards: FC = () => {
  const reservation = useAppSelector(selectedReservationDataSelector);

  const data = useMemo(() => {
    const type = reservation
      ? commitmentsResourceTypeNames[reservation.type]
      : "";
    const status = reservation
      ? commitmentsStateLabels[reservation?.state]
      : "";
    const paymentOption = reservation
      ? commitmentsPaymentOptionLabels[reservation.payment_option]
      : "";
    const tags = reservation?.tags?.length ? (
      <TagCell tags={reservation.tags} widthToEllipsis={130} />
    ) : (
      "-"
    );

    return {
      Type: type,
      "Instance Type": reservation?.instance_type,
      "Instance Count": reservation?.instance_count,
      Platform: reservation?.platform,
      "Payment Option": paymentOption,
      Account: (
        <AccountCell
          TypographyProps={typographyProps}
          accountName={reservation?.account?.provider_account_name}
          isRootAccount={reservation?.account?.is_provider_root_account}
        />
      ),

      "Start Date": (
        <DatesCell
          TypographyProps={typographyProps}
          date={reservation?.start_date ?? ""}
        />
      ),

      "End Date": (
        <DatesCell
          TypographyProps={typographyProps}
          date={reservation?.end_date ?? ""}
        />
      ),

      Status: status,

      "Offering Class": capitalizeString(reservation?.offering_class ?? ""),

      "Region/AZ": reservation?.region,

      "Total Commitment":
        reservation?.total_commitment === null
          ? "N/A"
          : `${reservation?.total_commitment}h`,

      Tags: tags,
    };
  }, [reservation]);

  if (!data) {
    return null;
  }

  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {Object.entries(data).map(([title, value]) => {
        const props = {
          title,
          key: title,
          valueSx: value === "Active" ? statusActiveStyles : undefined,
        };

        if (typeof value === "string") {
          return <CommitmentsDrawerHeaderInfoCard {...props} value={value} />;
        }

        return (
          <CommitmentsDrawerHeaderInfoCard {...props}>
            {value}
          </CommitmentsDrawerHeaderInfoCard>
        );
      })}
    </Stack>
  );
};

const typographyProps: TypographyProps = {
  variant: "body2",
  fontWeight: "medium",
};

const statusActiveStyles = {
  color: "white",
  bgcolor: "success.light",
};
