import { FC } from "react";
import AppsIcon from "@mui/icons-material/Apps";
import CloudOutlinedIcon from "@mui/icons-material/CloudOutlined";
import { isStringProviderType } from "../../../../../utils/helpers/providers/isStringProviderType";
import { ProviderLogo } from "../../../../common/provider-logo/ProviderLogo";
import dimensions from "../../../../../assets/images/icons/dimensions.svg";
import { ResourceExplorerFilterProvider } from "../../../../../services/cloudchipr.api";

interface FilterProviderIconProps {
  filterProvider: ResourceExplorerFilterProvider | "all";
}

export const FilterProviderIcon: FC<FilterProviderIconProps> = ({
  filterProvider,
}) => {
  if (isStringProviderType(filterProvider)) {
    return <ProviderLogo provider={filterProvider} />;
  }

  if (filterProvider === "all") {
    return <AppsIcon color="primary" fontSize="small" />;
  }

  if (filterProvider === "dimensions") {
    return <img src={dimensions} alt="dimensions" />;
  }

  if (filterProvider === "global") {
    return <CloudOutlinedIcon />;
  }

  return filterProvider;
};
