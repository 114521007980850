import { anyChangesExistOnCategoryEditSelector } from "./anyChangesExistOnCategoryEditSelector";
import { editingCategorySelector } from "../../slice-data/editing-category/editingCategorySelector";
import { categoryFiltersAreInvalid } from "../../../utils/helpers/categoryFiltersAreInvalid";
import { RootState } from "../../../../store";
import { categoryFilterTreeIsInvalid } from "../../../utils/helpers/categoryFilterTreeIsInvalid";

export const categoriesCreateDisabledSelector = (state: RootState) => {
  const editingCategory = editingCategorySelector(state);

  if (!editingCategory) {
    return;
  }

  const invalid = editingCategory.filter_tree
    ? categoryFilterTreeIsInvalid(editingCategory.filter_tree)
    : categoryFiltersAreInvalid(editingCategory);

  const somethingChanged = anyChangesExistOnCategoryEditSelector(state);

  return (
    !editingCategory?.name ||
    invalid ||
    !somethingChanged ||
    !editingCategory.name
  );
};
