import { FC, ReactNode } from "react";
import {
  Box,
  Card,
  Collapse,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { useToggle } from "rooks";

interface CommitmentsDrawerContentProps {
  title: string;
  icon: "utilization" | "recommendation";
  children?: ReactNode;
  padding?: string | number;
}

export const CommitmentsDrawerContent: FC<CommitmentsDrawerContentProps> = ({
  icon,
  title,
  children,
  padding,
}) => {
  const [collapsed, toggleCollapsed] = useToggle();

  return (
    <Box p={padding ?? 2}>
      <Card variant="outlined" sx={{ borsderRadius: 3 }}>
        <Stack
          p={1}
          direction="row"
          spacing={1}
          alignItems="center"
          borderBottom={1}
          borderColor="grey.300"
        >
          <IconButton size="small" onClick={toggleCollapsed}>
            {collapsed ? (
              <KeyboardArrowDownOutlinedIcon />
            ) : (
              <KeyboardArrowUpOutlinedIcon />
            )}
          </IconButton>

          {icon === "utilization" ? (
            <AnalyticsOutlinedIcon fontSize="small" />
          ) : (
            <LightbulbOutlinedIcon fontSize="small" />
          )}

          <Typography variant="body2" fontWeight="medium">
            {title}
          </Typography>
        </Stack>

        <Collapse in={!collapsed}>
          <Box p={1.5}>{children}</Box>
        </Collapse>
      </Card>
    </Box>
  );
};
