import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  ResourceExplorerDynamicFilterItemType,
  ResourceExplorerFilterProvider,
  useGetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesQuery as useValuesQuery,
} from "../../../../../../../../../services/cloudchipr.api";
import { FilterItemNodeWithId } from "../../../../../utils/types/common";

interface FilterValueSingleSelectProps {
  filterType: ResourceExplorerDynamicFilterItemType;
  filterValue: string;
  provider: ResourceExplorerFilterProvider;
  onChange(value: FilterItemNodeWithId["value"]): void;
}

export const FilterValueSingleSelect: FC<FilterValueSingleSelectProps> = ({
  provider,
  filterType,
  onChange,
  filterValue,
}) => {
  const { data } = useValuesQuery({
    filterProvider: provider,
    filterType: filterType,
  });

  const changeHandler = useCallback(
    (event: SelectChangeEvent) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <FormControl fullWidth size="xsmall">
      <InputLabel>Value</InputLabel>

      <Select label="Value" value={filterValue} onChange={changeHandler}>
        {data?.map((option) => {
          return (
            <MenuItem value={option.value} key={option.value}>
              {option.title ?? option.value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
