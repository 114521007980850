import { FC } from "react";
import { CSVLink } from "react-csv";
import { IconButton, Tooltip } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import moment from "moment";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { commitmentsRecommendationsCSVDataSelector } from "../../../../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsCSVDataSelector";
import { commitmentsCurrentOrgIdSelector } from "../../../../../../../../store/commitments/selectors/common/commitmentsCurrentOrgIdSelector";
import { formatDate } from "../../../../../../../../utils/helpers/date-time/datetime-format";

export const RecommendationsCsvDataDownloader: FC = () => {
  const csvData = useAppSelector(commitmentsRecommendationsCSVDataSelector);
  const orgId = useAppSelector(commitmentsCurrentOrgIdSelector);

  const fileName = `${formatDate(moment(), {
    type: "date",
  })}-${orgId}-Org_commitments_recommendations`;

  if (!csvData) {
    return null;
  }

  return (
    <Tooltip arrow title="Export CSV" placement="top">
      <IconButton
        disabled={!csvData?.length}
        size="small"
        sx={{
          "& a": { textDecoration: "none", color: "inherit", lineHeight: 0.5 },
        }}
      >
        <CSVLink
          filename={`${fileName}.csv`}
          data={csvData}
          enclosingCharacter=""
          separator=","
        >
          <DownloadOutlinedIcon fontSize="small" />
        </CSVLink>
      </IconButton>
    </Tooltip>
  );
};
