import { convertCategoriesFiltersToDynamicFilters } from "./convertCategoriesFiltersToDynamicFilters";
import { addIdsToCategoryFilterTreeItems } from "./addIdsToCategoryFilterTreeItems";
import { DimensionCategories } from "../../../../../store/dimensions/utils/types/common";
import { CategoryWithCosts } from "../../../../../services/cloudchipr.api";

export const generateDimensionCategoriesFromCategoriesWithCosts = (
  categories: CategoryWithCosts[],
) => {
  return categories.reduce((acc, category) => {
    const filterTree = category.filters.filter_tree
      ? addIdsToCategoryFilterTreeItems(category.filters.filter_tree)
      : undefined;

    acc[category.id] = {
      ...category,
      filter_tree: filterTree,
      filters: convertCategoriesFiltersToDynamicFilters(category.filters),
    };
    return acc;
  }, {} as DimensionCategories);
};
