import { FC, Fragment, useCallback } from "react";
import { CostByCategoryGroupSelect } from "./components/app-bar/CostByCategoryGroupSelect";
import { CostByCategoryFilteredResourcesSwitcher } from "./components/app-bar/CostByCategoryFilteredResourcesSwitcher";
import { CostByCategoryChartTypeSelect } from "./components/app-bar/CostByCategoryChartTypeSelect";
import { CostByCategorySetupContent } from "./components/content/CostByCategorySetupContent";
import {
  DateData,
  WidgetSetupDateRangeSelect,
} from "../common/toolbar/date-picker/WidgetSetupDateRangeSelect";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { WidgetSetupLayout } from "../common/WidgetSetupLayout";
import { costByCategorySetupChangeThunk } from "../../../../../../../../store/dashboards/thunks/widgets/cost-by-category/costByCategorySetupChangeThunk";
import { WidgetFrequencySelect } from "../common/toolbar/WidgetFrequencySelect";
import { WidgetForecastSelect } from "../common/toolbar/WidgetForecastSelect";
import { costByCategoryWidgetForecastedOptionsSelector } from "../../../../../../../../store/dashboards/selectors/widgets/cost-by-category/costByCategoryWidgetForecastedOptionsSelector";
import { costByCategorySetupPropertyByKeySelector } from "../../../../../../../../store/dashboards/selectors/setups/cost-by-category/costByCategorySetupPropertyByKeySelector";
import { WidgetSetupHeader } from "../common/WidgetSetupHeader";
import { buildCostByCategoryWidgetThunk } from "../../../../../../../../store/dashboards/thunks/widgets/cost-by-category/actions/buildCostByCategoryWidgetThunk";
import { scrollPageToBottom } from "../../../../../../utils/helpers/page-scrolling-functions";
import { costByCategorySubmitButtonDisabledSelector } from "../../../../../../../../store/dashboards/selectors/setups/cost-by-category/costByCategorySubmitButtonDisabledSelector";

interface CostByCategorySetupProps {
  onClose(): void;
  onSubmit(): void;
}

export const CostByCategorySetup: FC<CostByCategorySetupProps> = ({
  onClose,
  onSubmit,
}) => {
  const dispatch = useAppDispatch();

  const categoryGroup = useAppSelector(
    costByCategorySetupPropertyByKeySelector("categoryGroup"),
  );
  const setupDate = useAppSelector(
    costByCategorySetupPropertyByKeySelector("date"),
  );
  const frequency = useAppSelector(
    costByCategorySetupPropertyByKeySelector("frequency"),
  );
  const chartType = useAppSelector(
    costByCategorySetupPropertyByKeySelector("chartType"),
  );
  const forecastOption = useAppSelector(
    costByCategorySetupPropertyByKeySelector("forecastOption"),
  );
  const forecastedCostsOptions = useAppSelector(
    costByCategoryWidgetForecastedOptionsSelector,
  );
  const disabled = useAppSelector(costByCategorySubmitButtonDisabledSelector);
  const setupId = useAppSelector(
    costByCategorySetupPropertyByKeySelector("id"),
  );

  const dateChangeHandler = useCallback(
    (date: DateData) => {
      dispatch(costByCategorySetupChangeThunk({ date }));
    },
    [dispatch],
  );

  const frequencyChangeHandler = useCallback(
    (e: any) => {
      dispatch(costByCategorySetupChangeThunk({ frequency: e.target.value }));
    },
    [dispatch],
  );

  const forecastChangeHandler = useCallback(
    (event: any) => {
      dispatch(
        costByCategorySetupChangeThunk({
          forecastOption: event.target.value,
        }),
      );
    },
    [dispatch],
  );

  const submitHandler = useCallback(async () => {
    const responseId = await dispatch(
      buildCostByCategoryWidgetThunk(setupId ? "update" : "create"),
    ).unwrap();
    onSubmit();

    if (responseId && !setupId) {
      scrollPageToBottom();
    }
  }, [dispatch, setupId, onSubmit]);

  return (
    <Fragment>
      <WidgetSetupHeader
        disabled={disabled}
        onAdd={submitHandler}
        onCancel={onClose}
      />

      <WidgetSetupLayout
        empty={!categoryGroup ? "Select a Category Group  to get started." : ""}
        appBar={
          <Fragment>
            <CostByCategoryGroupSelect />
            <WidgetSetupDateRangeSelect
              dates={setupDate}
              onChange={dateChangeHandler}
            />
            <WidgetFrequencySelect
              frequency={frequency}
              onChange={frequencyChangeHandler}
              disabled={chartType === "pie"}
            />
            <CostByCategoryChartTypeSelect />
            <CostByCategoryFilteredResourcesSwitcher />

            <WidgetForecastSelect
              forecast={forecastOption}
              onChange={forecastChangeHandler}
              options={forecastedCostsOptions}
            />
          </Fragment>
        }
      >
        <CostByCategorySetupContent />
      </WidgetSetupLayout>
    </Fragment>
  );
};
