import { FC, Fragment, useCallback } from "react";
import { CostBreakdownV2WidgetSetupContent } from "./content/CostBreakdownV2WidgetSetupContent";
import { CostBreakdownV2WidgetSetupSidebar } from "./side-bar/CostBreakdownV2WidgetSetupSidebar";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { WidgetSetupLayout } from "../common/WidgetSetupLayout";
import { WidgetSetupHeader } from "../common/WidgetSetupHeader";
import { scrollPageToBottom } from "../../../../../../utils/helpers/page-scrolling-functions";
import { buildCostBreakdownV2WidgetThunkThunk } from "../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/actions/buildCostBreakdownV2WidgetThunkThunk";
import { costBreakdownV2SubmitButtonDisabledSelector } from "../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SubmitButtonDisabledSelector";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";

interface CostBreakdownSetupProps {
  onClose(): void;
  onSubmit(): void;
}

export const CostBreakdownV2Setup: FC<CostBreakdownSetupProps> = ({
  onClose,
  onSubmit,
}) => {
  const dispatch = useAppDispatch();

  const viewId = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("viewId"),
  );

  const viewType = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("viewType"),
  );

  const setupId = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("id"),
  );
  const disabled = useAppSelector(costBreakdownV2SubmitButtonDisabledSelector);

  const submitHandler = useCallback(async () => {
    const responseId = await dispatch(
      buildCostBreakdownV2WidgetThunkThunk(),
    ).unwrap();

    onSubmit();
    if (responseId && !setupId) {
      scrollPageToBottom();
    }
  }, [dispatch, setupId, onSubmit]);

  if (!viewType) {
    return null;
  }

  return (
    <Fragment>
      <WidgetSetupHeader
        disabled={disabled}
        onAdd={submitHandler}
        onCancel={onClose}
      />

      <WidgetSetupLayout
        empty={!viewId ? "Select Resource Explorer View to get started." : ""}
        appBar={<CostBreakdownV2WidgetSetupSidebar />}
      >
        <CostBreakdownV2WidgetSetupContent />
      </WidgetSetupLayout>
    </Fragment>
  );
};
