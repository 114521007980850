import { FC, useCallback, useMemo } from "react";
import { FilterKeySelectTriggerComponent } from "./FilterKeySelectTriggerComponent";
import {
  KeyValuePair,
  useGetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesQuery as useValuesQuery,
} from "../../../../../../../../../services/cloudchipr.api";
import { DropdownSelect } from "../../../../../../../../common/select/dropdown-select/DropdownSelect";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";
import { FilterTriggerComponentProps } from "../../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { FilterItemNodeWithId } from "../../../../../utils/types/common";

export type FilterWithKey = { value: KeyValuePair } & FilterItemNodeWithId;

interface FilterKeySelectProps {
  index: number;
  filter: FilterWithKey;
  onFilterChange(filter: FilterItemNodeWithId, index: number): void;
}

export const FilterKeySelect: FC<FilterKeySelectProps> = ({
  onFilterChange,
  filter,
  index,
}) => {
  const { data, isLoading } = useValuesQuery({
    filterProvider: filter.filter_provider,
    filterType: filter.type,
  });

  const renderOptions = useMemo(() => {
    return data?.map((option) => ({
      rawValue: option,
      value: option.value,
      label: <TypographyWithTooltip title={option.title ?? option.value} />,
    }));
  }, [data]);

  const filterTypeChangeHandler = useCallback(
    (values: string[]) => {
      const key = values?.at(0);

      if (filter?.value?.key === key || !key) {
        return;
      }

      onFilterChange({ ...filter, value: { key, value: [] } }, index);
    },
    [index, onFilterChange, filter],
  );

  const value = filter.value?.key;

  const TriggerComponent = (props: FilterTriggerComponentProps) => (
    <FilterKeySelectTriggerComponent {...props} />
  );

  return (
    <DropdownSelect
      singleSelect
      label="Key"
      options={renderOptions ?? []}
      optionsLoading={isLoading}
      wrapperVariant="popover"
      submitHandlerOnClose={filterTypeChangeHandler}
      values={value ? [value] : []}
      TriggerComponent={TriggerComponent}
    />
  );
};
