import { FC } from "react";
import { Stack } from "@mui/material";
import { CommitmentsDrawerChartHeader } from "../../../../common/components/drawer/CommitmentsDrawerChartHeader";
import { useAppSelector } from "../../../../../../../store/hooks";
import { CommitmentsDrawerContentInfoCard } from "../../../../common/components/drawer/CommitmentsDrawerContentInfoCard";
import { CommitmentsProgressBar } from "../../../../common/components/CommitmentsProgressBar";
import { money } from "../../../../../../../utils/numeral/money";
import { reservationDetailsLoadingSelector } from "../../../../../../../store/commitments/selectors/reservations/details-drawer/data/reservationDetailsLoadingSelector";
import { reservationCoverageDetailsMetaDataSelector } from "../../../../../../../store/commitments/selectors/reservations/details-drawer/resource-type/reservationCoverageDetailsMetaDataSelector";

export const ReservationCoverageDrawerChartHeader: FC = () => {
  const metadata = useAppSelector(reservationCoverageDetailsMetaDataSelector);
  const isLoading = useAppSelector(reservationDetailsLoadingSelector);

  if (!metadata) {
    return null;
  }

  return (
    <CommitmentsDrawerChartHeader>
      <Stack direction="row" flexWrap="wrap" gap={1} mt={1.5}>
        <CommitmentsDrawerContentInfoCard
          cardSx={cardSx}
          loading={isLoading}
          title="Coverage"
        >
          <CommitmentsProgressBar width="100%" value={metadata.coverage} />
        </CommitmentsDrawerContentInfoCard>

        <CommitmentsDrawerContentInfoCard
          cardSx={cardSx}
          loading={isLoading}
          title="Spend Covered by Commitment"
          value={money(metadata.covered_usage)}
        />

        <CommitmentsDrawerContentInfoCard
          cardSx={cardSx}
          loading={isLoading}
          title="On-Demand Spend"
          value={money(metadata.uncovered_usage)}
        />

        <CommitmentsDrawerContentInfoCard
          cardSx={cardSx}
          loading={isLoading}
          title="Net Savings"
          value={money(metadata.net_savings)}
        />
      </Stack>
    </CommitmentsDrawerChartHeader>
  );
};

const cardSx = { minWidth: 256, flex: 1 };
