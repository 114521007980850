import { largestCostChangesSetupPropertyByKeySelector } from "./largestCostChangesSetupPropertyByKeySelector";
import { RootState } from "../../../../store";
import { WidgetTitleProps } from "../../../../../components/pages/dashboard/components/widgets/common/widget-header/source-and-name/WidgetSourceInfo";
import { getResourcesExplorerGroupingLabel } from "../../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { widgetViewNameByViewIdIdSelector } from "../../widgets/common/widgetViewNameByViewIdIdSelector";
import { selectedViewProvidersSelector } from "../../widgets/common/selectedViewProvidersSelector";
import { objectToUrl } from "../../../../../components/utils/helpers/dataToUrlToDataConvertors";

export const largestCostChangesSetupWidgetTitleSelector = (
  state: RootState,
): WidgetTitleProps => {
  const grouping =
    largestCostChangesSetupPropertyByKeySelector("grouping")(state);
  const groupingValues =
    largestCostChangesSetupPropertyByKeySelector("group_values")(state);

  const providers = selectedViewProvidersSelector(state);

  const widgetName =
    largestCostChangesSetupPropertyByKeySelector("name")(state);

  const dates = largestCostChangesSetupPropertyByKeySelector("dates")(state);
  const viewId = largestCostChangesSetupPropertyByKeySelector("viewId")(state);
  const viewName = widgetViewNameByViewIdIdSelector(state, viewId);

  const groupingLabel = getResourcesExplorerGroupingLabel(
    providers,
    grouping,
    true,
  );
  const path = objectToUrl([
    {
      groupedBy: grouping,
      groupValues: groupingValues,
      dates: dates,
      includedFilters: {},
    },
  ]);

  return {
    grouping: groupingLabel,
    widgetName,
    dates: dates,
    sourceData: {
      icon: "pie",
      title: viewName ?? "",
      menu: [
        {
          url: `/resource-explorer/${viewId}?path=${path}`,
          title: "Open in Resource Explorer",
        },
      ],
    },
  };
};
