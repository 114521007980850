import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "../../../../store";
import { getWidgetDataByViewIdThunk } from "../common/getWidgetDataByViewIdThunk";
import { costBreakdownWidgetSetupSelector } from "../../../selectors/setups/cost-breakdown/costBreakdownWidgetSetupSelector";
import {
  buildingInProgressWidgetId,
  previousPeriodVisualizationTypes,
} from "../utils/constants";

export const fetchCostBreakdownWidgetDataBySetupThunk = createAsyncThunk(
  "dashboards/fetchCostBreakdownWidgetDataBySetup",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const setup = costBreakdownWidgetSetupSelector(state);

    if (!setup?.viewId) {
      return;
    }

    return await dispatch(
      getWidgetDataByViewIdThunk({
        resourceExplorerViewId: setup.viewId,
        widgetType: "cost_breakdown",
        grouping: setup.grouping,
        groupValues: setup.group_values,
        dateLabel: setup.date.label,
        dateTo: setup.date.to,
        dateFrom: setup.date.from,
        frequency: setup.frequency,
        widgetId: buildingInProgressWidgetId,
        loadDetailedPreviousPeriodData: previousPeriodVisualizationTypes.has(
          setup.viewType,
        ),
        monthlyForecast: setup.forecastOption === "month",
        quarterlyForecast: setup.forecastOption === "quarter",
      }),
    ).unwrap();
  },
);
