import { grey } from "@mui/material/colors";
import { StylesProps } from "../../../../../../../storybook/data-grid/utils/types/prop-types";

export const getCommitmentsTableStyles = (
  empty: boolean,
  topAlignedColumns?: boolean,
) => {
  if (!styles) {
    return undefined;
  }

  if (empty) {
    return emptyStyles;
  }

  if (topAlignedColumns) {
    return topAlignedStyles;
  }

  return styles;
};

const styles: StylesProps["styles"] = {
  table: {
    "& th": { bgcolor: "grey.100", height: 60, verticalAlign: "middle" },
    "& th:first-of-type": { pl: 2 },
    "& td:first-of-type": { pl: 2 },
    "& tr:hover td": { bgcolor: grey[50] },
    "& tr td": { borderColor: grey[100], height: 60 },
  },

  tableContainer: {
    bgcolor: "white",
    borderRadius: 2,
    border: "1px solid",
    borderColor: "grey.300",
  },
};

const topAlignedStyles = {
  ...styles,

  table: {
    ...styles.table,
    "& th": { bgcolor: "grey.100", height: 60 },
  },
};

const emptyStyles = {
  ...styles,
  tableContainer: { ...styles.tableContainer, borderRadius: "8px 8px 0 0" },
};
