import { FC } from "react";
import { Skeleton, Stack } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ProviderLogo } from "../../../../../../common/provider-logo/ProviderLogo";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { SIDEBAR_WIDTH } from "../../../../../../../utils/constants/style-sizes";
import { useAppSelector } from "../../../../../../../store/hooks";
import { FilterTriggerComponentProps } from "../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { liveUsageMgmtProviderSelector } from "../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import { liveUsageMgmtAccountIdsSelector } from "../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAccountIdsSelector";

export const LiveUsageMgmtSelectedAccountNavigationTriggerComponent: FC<
  FilterTriggerComponentProps
> = ({ onClick, open }) => {
  const accountIds = useAppSelector(liveUsageMgmtAccountIdsSelector);
  const provider = useAppSelector(liveUsageMgmtProviderSelector);

  if (!accountIds || !provider) {
    return <Skeleton variant="text" height={100} width={SIDEBAR_WIDTH - 20} />;
  }

  return (
    <Stack
      py={0.5}
      px={1}
      direction="row"
      borderRadius={2}
      onClick={onClick}
      bgcolor="grey.100"
      alignItems="center"
      sx={{ cursor: "pointer" }}
      justifyContent="space-between"
      spacing={1}
    >
      <ProviderLogo provider={provider} width={24} />
      <TypographyWithTooltip
        title={`${accountIds.length} selected accounts`}
        variant="subtitle2"
      />
      {open ? (
        <ArrowRightIcon sx={{ mr: 1 }} />
      ) : (
        <ArrowDropDownIcon sx={{ mr: 1 }} />
      )}
    </Stack>
  );
};
