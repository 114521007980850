import { Stack } from "@mui/material";
import { ReservationsDataGridViewTabs } from "./ReservationsDataViewTabs";
import { ReservationsCSVDownloader } from "./ReservationsCSVDownloader";
import { CoverageDataGridGrouping } from "../../../../../common/components/data-grid/CoverageDataGridGrouping";
import { GlobalFilter } from "../../../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { RenderToolbar } from "../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { commitmentsDataViewTabSelector } from "../../../../../../../../store/commitments/selectors/common/view-options/commitmentsDataViewTabSelector";
import { ColumnsShowHideSwitcher } from "../../../../../common/components/data-grid/ColumnsShowHideSwitcher";

export const ReservationsDataGridToolbar: RenderToolbar<unknown> = ({
  setGlobalFilter,
  globalFilter,
  table,
}) => {
  const tab = useAppSelector(commitmentsDataViewTabSelector);

  return (
    <Stack
      pb={2}
      direction="row"
      alignItems="start"
      justifyContent="space-between"
    >
      <Stack spacing={2}>
        <ReservationsDataGridViewTabs />

        {tab === "coverage" && <CoverageDataGridGrouping />}
      </Stack>

      <Stack alignItems="center" direction="row" spacing={2}>
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          size="small"
        />

        <ReservationsCSVDownloader />

        <ColumnsShowHideSwitcher table={table} />
      </Stack>
    </Stack>
  );
};
