import { FC, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffectOnceWhen } from "rooks";
import { Stack } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { ResourceExplorerAppBar } from "./components/app-bar/ResourceExplorerAppBar";
import { ResourceExplorerCard } from "./components/resource-explorer-card/ResourceExplorerCard";
import { ResourceExplorerAlerts } from "./components/alerts/ResourceExplorerAlerts";
import { setAzureTagsFeatureToggleToStorage } from "./utils/helpers/azure-tags/setAzureTagsFeatureToggleToStorage";
import { useAppSelector } from "../../../store/hooks";
import { resourceExplorerViewByIdLoadingSelector } from "../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerViewByIdLoadingSelector";
import { defaultResourceExplorerViewIdSelector } from "../../../store/resource-explorer/selectors/all-resource-explorers/defaultResourceExplorerViewIdSelector";
import { initiateResourceExplorerThunk } from "../../../store/resource-explorer/thunks/resource-explorer/initiateResourceExplorerThunk";
import { pathKeyInURL } from "../../../store/resource-explorer/thunks/widgets/path/setResourceExplorerPathThunk";
import { isBillingStatusesGettingFulfilledSelector } from "../../../store/common/selectors/billing-status/isBillingStatusesGettingFulfilledSelector";

export const ResourceExplorer: FC = () => {
  const { viewId = "" } = useParams<{
    viewId: string;
  }>();
  const enableDimensions = useFlag("EnableDimensions");
  const enableAzureTags = useFlag("EnableAzureBillingExportProcessing");

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const path = searchParams.get?.(pathKeyInURL);
  const dimensionId = searchParams.get("dimensionId") as string;
  const categoryId = searchParams.get("categoryId") as string;

  const dispatch = useDispatch();

  const viewGettingLoading = useAppSelector((state) =>
    resourceExplorerViewByIdLoadingSelector(state, viewId),
  );
  const isBillingStatusesGettingFulfilled = useAppSelector(
    isBillingStatusesGettingFulfilledSelector,
  );
  const defaultResourceExplorerViewId = useAppSelector(
    defaultResourceExplorerViewIdSelector,
  );

  useEffect(() => {
    if (viewId) {
      dispatch(
        initiateResourceExplorerThunk({
          path,
          viewId,
          dimensionId,
          categoryId,
        }),
      );
    }
  }, [viewId, dispatch, path, dimensionId, categoryId]);

  useEffectOnceWhen(() => {
    navigate(`${defaultResourceExplorerViewId}`);
  }, !viewId && !!defaultResourceExplorerViewId);

  useEffect(() => {
    setAzureTagsFeatureToggleToStorage(enableAzureTags);
  }, [enableAzureTags]);

  if (!viewId || !isBillingStatusesGettingFulfilled) {
    return null;
  }

  return (
    <Stack height="100dvh">
      {!enableDimensions && <ResourceExplorerAlerts viewId={viewId} />}

      <ResourceExplorerAppBar viewId={viewId} />

      {!viewGettingLoading && <ResourceExplorerCard viewId={viewId} />}
    </Stack>
  );
};
