import { RootState } from "../../../../store";
import { dimensionCategoriesEntriesSelector } from "../../slice-data/dimensionCategoriesEntriesSelector";
import { categoryFiltersAreInvalid } from "../../../utils/helpers/categoryFiltersAreInvalid";
import { categoryFilterTreeIsInvalid } from "../../../utils/helpers/categoryFilterTreeIsInvalid";

export const categoriesCreationDataIsInvalidSelector = (state: RootState) => {
  const categories = dimensionCategoriesEntriesSelector(state);

  let invalid = false;

  categories.forEach(([, category]) => {
    if (invalid) {
      return;
    }

    invalid = !category.name;

    if (invalid) {
      return;
    }

    invalid = category.filter_tree
      ? categoryFilterTreeIsInvalid(category.filter_tree)
      : categoryFiltersAreInvalid(category);
  });

  return invalid;
};
