import { FC, Fragment, useCallback } from "react";
import { useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import {
  Chip,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { NavItemProps } from "../NavItem";
import { useNavigationOverlayContext } from "../utils/useNavigationOverlay.context";
import { useHover } from "../../../../../utils/hooks/useHover.hook";
import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";
import { useAppSelector } from "../../../../../store/hooks";
import { isSubscriptionDisabledSelector } from "../../../../../store/subscriptions/subscriptionsSelectors";

interface NavItemExpandedProps extends NavItemProps {}

export const NavItemExpanded: FC<NavItemExpandedProps> = ({
  primary,
  to,
  icon: Icon,
  selected,
  disabled,
  secondaryAction,
  secondaryActionHover,
  nested,
  iconProps,
  expandable,
  primaryTypographyProps,
  onButtonClick,
  expanded,
  comingSoon,
  listItemButtonSx,
  tooltipTitle,
}) => {
  const { ref, hovered } = useHover();
  const resolvedPath = useResolvedPath(to || "");
  const match = useMatch({ path: resolvedPath.pathname });
  const navigate = useNavigate();

  selected = (resolvedPath.pathname !== "/" && !!match) || selected;

  const isSubscriptionDisabled = useAppSelector(isSubscriptionDisabledSelector);

  disabled = disabled || isSubscriptionDisabled;

  const { onNavigate } = useNavigationOverlayContext();

  const handleOnClick = useCallback(() => {
    if (to) {
      navigate(to);
    }

    onNavigate?.();
  }, [to, navigate, onNavigate]);

  const clickHandler = to ? handleOnClick : onButtonClick;
  const buttonProps = {
    onClick: clickHandler,
  };

  const showArrowDropdownIcon =
    expandable && hovered && onButtonClick && !disabled;
  const arrowDropDownIconTransform = expanded
    ? "rotate(0deg)"
    : "rotate(-90deg)";
  const isListItemSelected = expandable ? !expanded && selected : selected;
  const navItemContent = (
    <Fragment>
      <ListItemIcon sx={listItemIconSx}>
        {showArrowDropdownIcon ? (
          <ArrowDropDownIcon sx={{ transform: arrowDropDownIconTransform }} />
        ) : (
          Icon && (
            <Icon
              fontSize="small"
              color={selected ? "primary" : undefined}
              {...iconProps}
            />
          )
        )}
      </ListItemIcon>

      <ListItemText
        primary={
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <TypographyWithTooltip title={primary} variant="inherit" />

            {comingSoon &&
              (typeof comingSoon === "boolean" ? (
                <Chip
                  variant="filled"
                  color="success"
                  label="Coming Soon"
                  size="small"
                  sx={{ fontSize: 10, height: 16, borderRadius: 1 }}
                />
              ) : (
                comingSoon
              ))}
          </Stack>
        }
        primaryTypographyProps={{
          ...primaryTypographyPropsOverride,
          color: selected ? "primary" : undefined,
          ...primaryTypographyProps,
        }}
      />
    </Fragment>
  );

  return (
    <Tooltip arrow placement="top" title={tooltipTitle}>
      <ListItem
        sx={{
          ...listItemSx,
          ...(secondaryActionHover ? listItemSecondaryActionHoverable : {}),
        }}
        secondaryAction={secondaryAction}
        ref={ref}
      >
        {onButtonClick ? (
          <ListItemButton
            {...buttonProps}
            selected={isListItemSelected}
            disabled={disabled}
            sx={{
              pl: nested ? 3 : 1,
              borderRadius: 2,
              paddingRight: hovered ? "60px !important" : "8px !important",
              ...listItemButtonSx,
            }}
          >
            {navItemContent}
          </ListItemButton>
        ) : (
          navItemContent
        )}
      </ListItem>
    </Tooltip>
  );
};

const primaryTypographyPropsOverride = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const listItemSx = { px: 1, py: 0.25 };

const listItemSecondaryActionHoverable = {
  ".MuiListItemSecondaryAction-root": {
    visibility: "hidden",
  },
  "&:hover": {
    ".MuiListItemSecondaryAction-root": {
      visibility: "visible",
    },
  },
};
const listItemIconSx = {
  minWidth: 32,
};
