import { FC, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DashboardV2FoldersDeleteDialogContent } from "./DashboardV2FoldersDeleteDialogContent";
import { getNextDashboardId } from "../../../../dashboard/components/utils/getNextDashboardId";
import { DeleteConfirmationDialog } from "../../../../../../common/modals/DeleteConfirmationDialog";
import { deleteDashboardFolderThunk } from "../../../../../../../store/dashboards/thunks/common/deleteDashboardFolderThunk";
import { dashboardHierarchyItemsByVisibilitySelector } from "../../../../../../../store/dashboards/selectors/dashboard-hierarchy/dashboardHierarchyItemsByVisibilitySelector";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { deleteDashboardHierarchyFolderLoadingSelector } from "../../../../../../../store/dashboards/selectors/dashboard/loadings/deleteDashboardHierarchyFolderLoadingSelector";
import { dashboardHierarchyItemsByFolderIdSelector } from "../../../../../../../store/dashboards/selectors/dashboard-hierarchy/dashboardHierarchyItemsByFolderIdSelector";
import { NavigationItemsVisibilityType } from "../../../../../../../store/navigation/utils/types";

interface DashboardV2FoldersDeleteDialogProps {
  id: string;
  open: boolean;
  name: string;
  closeMenu(): void;
  visibility: NavigationItemsVisibilityType;
}

export const DashboardV2FoldersDeleteDialog: FC<
  DashboardV2FoldersDeleteDialogProps
> = ({ closeMenu, id, name, open, visibility }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { dashboardId: activeDashboardId } = useParams<{
    dashboardId: string;
  }>();

  const loading = useAppSelector(deleteDashboardHierarchyFolderLoadingSelector);

  const dashboardHierarchy = useAppSelector((state) =>
    dashboardHierarchyItemsByVisibilitySelector(state, visibility),
  );

  const folderItems = useAppSelector((state) =>
    dashboardHierarchyItemsByFolderIdSelector(state, id, visibility),
  );
  const removeFolderHandler = useCallback(async () => {
    await dispatch(deleteDashboardFolderThunk(id));

    if (activeDashboardId === id) {
      const nextDashboardId = getNextDashboardId(id, dashboardHierarchy ?? []);
      navigate({ pathname: `/dashboards/${nextDashboardId}` });
    }
  }, [dispatch, navigate, activeDashboardId, id, dashboardHierarchy]);

  const confirmRemoveFolderHandler = useCallback(async () => {
    await removeFolderHandler();
    closeMenu();
  }, [closeMenu, removeFolderHandler]);

  return (
    <DeleteConfirmationDialog
      open={open}
      title="Delete Folder?"
      onClose={closeMenu}
      onDelete={confirmRemoveFolderHandler}
      loading={loading}
      content={
        <DashboardV2FoldersDeleteDialogContent
          name={name}
          folderItems={folderItems}
        />
      }
    />
  );
};
