import { Typography } from "@mui/material";
import { getCoveredUsageColumn } from "./common/coveredUsageColumn";
import { netSavingsColumn } from "./common/netSavingsColumn";
import { coveredHoursColumn } from "./common/coveredHoursColumn";
import { uncoveredUsageColumn } from "./common/uncoveredUsageColumn";
import { ReservationsCoverageDataWithId } from "../../../../../../../common/utils/types";
import { CommitmentResourceType } from "../../../../../../../../../../services/cloudchipr.api";
import { commitmentsResourceTypeNames } from "../../../../../../../common/utils/constants/labels";
import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";
import { CommitmentsProgressBar } from "../../../../../../../common/components/CommitmentsProgressBar";

export const reservationCoverageGroupedByResourceTypeColumns: ColumnSetupType<ReservationsCoverageDataWithId>[] =
  [
    {
      id: "resource_type",
      header: "Resource Type",
      accessorKey:
        "resource_type.resource_type" as keyof ReservationsCoverageDataWithId,
      cell: ({ row }) => {
        const rt = row.original.resource_type
          ?.resource_type as CommitmentResourceType;

        return (
          <Typography variant="body2" textTransform="capitalize">
            {commitmentsResourceTypeNames[rt]}
          </Typography>
        );
      },
    },
    {
      id: "instance_type",
      accessorKey:
        "resource_type.instance_type" as keyof ReservationsCoverageDataWithId,
      header: "Instance Type",
      cell: ({ row }) => row.original.resource_type?.instance_type ?? "-",
    },
    {
      id: "platform",
      accessorKey:
        "resource_type.platform" as keyof ReservationsCoverageDataWithId,
      header: "Platform",
      cell: ({ row }) => row.original.resource_type?.platform ?? "-",
    },
    {
      id: "region",
      accessorKey:
        "resource_type.region" as keyof ReservationsCoverageDataWithId,
      header: "Region/AZ",
      cell: ({ row }) => row.original.resource_type?.region ?? null,
    },

    getCoveredUsageColumn("resource_type"),

    coveredHoursColumn,

    uncoveredUsageColumn,

    netSavingsColumn,

    {
      accessorKey: "coverage",
      header: "Coverage",
      cell: ({ row }) => (
        <CommitmentsProgressBar
          overrideColor="info.light"
          value={row.original.coverage}
        />
      ),
    },
  ];
