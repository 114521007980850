import { FC, useMemo } from "react";
import { usePreviousDifferent } from "rooks";
import { reservedInstancesTableColumns } from "./columns/reservedInstancesTableColumns";
import { useAppSelector } from "../../../../../../../store/hooks";
import { EmptyDataGridFooter } from "../../../../common/components/EmptyDataGridFooter";
import { coverageGroupingNames } from "../../../../common/utils/constants/labels";
import { DataGrid } from "../../../../../../../storybook/data-grid/DataGrid";
import { getCommitmentsTableStyles } from "../../../../common/utils/helpers/grid/getCommitmentsTableStyles";
import { ToolbarConfig } from "../../../../../../../storybook/data-grid/utils/types/prop-types";
import { reservationsDataSelector } from "../../../../../../../store/commitments/selectors/reservations/data/reservationsDataSelector";
import { reservationsForTableLoadingSelector } from "../../../../../../../store/commitments/selectors/reservations/data/loading/reservationsForTableLoadingSelector";
import { useCommitmentsColumnsVisibility } from "../../../../common/utils/hooks/useColumnsVisibility.hook";

interface ReservedInstancesDataGridProps {
  toolbar: ToolbarConfig;
}

export const ReservedInstancesDataGrid: FC<ReservedInstancesDataGridProps> = ({
  toolbar,
}) => {
  const data = useAppSelector(reservationsDataSelector);
  const loading = useAppSelector(reservationsForTableLoadingSelector);

  const { visibility, setVisibility } = useCommitmentsColumnsVisibility(
    "reservation",
    "commitment",
  );

  const prevData = usePreviousDifferent(data);
  const gridData = data ?? prevData;
  const empty = !loading && !gridData?.length;

  const pagination = useMemo(() => {
    if (!empty) {
      return undefined;
    }

    return {
      renderPagination: () => {
        return <EmptyDataGridFooter name={coverageGroupingNames.commitment} />;
      },
    };
  }, [empty]);

  if (!gridData) {
    return null;
  }

  return (
    <DataGrid
      columnVisibility={{
        initialVisibility: visibility,
        onColumnsVisibilityChange: setVisibility,
      }}
      columnResizeMode="onEnd"
      toolbar={toolbar}
      styles={getCommitmentsTableStyles(empty)}
      data={gridData}
      columns={reservedInstancesTableColumns}
      columnSorting={sortingConfig}
      pagination={pagination}
    />
  );
};

const sortingConfig = {
  initialSort: [{ id: "end_date", desc: false }],
};
