import { FC, useCallback, useState } from "react";
import { Dialog, DialogActions, DialogContent, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDidMount, useToggle } from "rooks";
import { FilterTemplatesDialogTabType } from "./utils/types";
import { SaveSimplifiedFilterTemplatesDialogTabs } from "./components/SaveSimplifiedFilterTemplatesDialogTabs";
import { SaveSimplifiedFilterTemplatesDialogSaveAsNew } from "./components/SaveSimplifiedFilterTemplatesDialogSaveAsNew";
import { SaveSimplifiedFilterTemplatesDialogRewriteExisting } from "./components/SaveSimplifiedFilterTemplatesDialogRewriteExisting";
import { DialogTitleClosable } from "../../../../../../common/dialog-utils/DialogTitleClosable";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { liveUsageMgmtAppliedFilterTemplateNameSelector } from "../../../../../../../store/live-usage-mgmt/selectors/filter-template/liveUsageMgmtAppliedFilterTemplateNameSelector";
import { createLiveUsageMgmtFilterTemplateFromAppliedFiltersThunk } from "../../../../../../../store/live-usage-mgmt/thunks/filter-template/createLiveUsageMgmtFilterTemplateFromAppliedFiltersThunk";
import { liveUsageMgmtAppliedFilterTemplateIsSourceCloudchiprSelector } from "../../../../../../../store/live-usage-mgmt/selectors/filter-template/liveUsageMgmtAppliedFilterTemplateIsSourceCloudchiprSelector";
import { liveUsageMgmtAppliedTemplateIdSelector } from "../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAppliedTemplateIdSelector";
import { patchLiveUsageMgmtFilterTemplateFromAppliedFiltersThunk } from "../../../../../../../store/live-usage-mgmt/thunks/filter-template/patchLiveUsageMgmtFilterTemplateFromAppliedFiltersThunk";

interface SaveSimplifiedFilterTemplatesDialogProps {
  open: boolean;
  onClose(): void;
}

export const SaveSimplifiedFilterTemplatesDialog: FC<
  SaveSimplifiedFilterTemplatesDialogProps
> = ({ open, onClose }) => {
  const dispatch = useAppDispatch();
  const [tab, setTab] = useState<FilterTemplatesDialogTabType>("saveAsNew");
  const [loading, toggleLoading] = useToggle(false);
  const [disabled, setDisabled] = useState(false);

  const appliedFilterTemplateName = useAppSelector(
    liveUsageMgmtAppliedFilterTemplateNameSelector,
  );
  const initialName = `${appliedFilterTemplateName} (copy)`;
  const [newFilterTemplateName, setNewFilterTemplateName] =
    useState(initialName);

  const isCloudchiprTamplate = useAppSelector(
    liveUsageMgmtAppliedFilterTemplateIsSourceCloudchiprSelector,
  );
  const filterTemplateId = useAppSelector(
    liveUsageMgmtAppliedTemplateIdSelector,
  );
  const initialId = isCloudchiprTamplate ? "" : (filterTemplateId ?? "");
  const [selectedFilterTemplateId, setSelectedFilterTemplateId] =
    useState(initialId);

  const handleTabChange = useCallback(
    (_: unknown, tab: FilterTemplatesDialogTabType) => {
      setTab(tab);
    },
    [],
  );

  const closeHandler = useCallback(() => {
    setNewFilterTemplateName(initialName);
    setTab("saveAsNew");
    onClose();
  }, [onClose, initialName]);

  const filterTemplateSaveHandler = useCallback(async () => {
    toggleLoading();

    if (tab === "saveAsNew") {
      await dispatch(
        createLiveUsageMgmtFilterTemplateFromAppliedFiltersThunk(
          newFilterTemplateName,
        ),
      );
    } else {
      await dispatch(
        patchLiveUsageMgmtFilterTemplateFromAppliedFiltersThunk(
          selectedFilterTemplateId,
        ),
      );
    }

    closeHandler();
    toggleLoading();
  }, [
    tab,
    dispatch,
    newFilterTemplateName,
    closeHandler,
    toggleLoading,
    selectedFilterTemplateId,
  ]);

  useDidMount(() => {
    setNewFilterTemplateName(initialName);
    setSelectedFilterTemplateId(initialId);
  });

  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      fullWidth
      PaperProps={{ sx: { borderRadius: 3 } }}
    >
      <DialogTitleClosable
        title="Save Filter Template"
        onClose={closeHandler}
      />

      <DialogContent dividers>
        <Stack spacing={2}>
          <SaveSimplifiedFilterTemplatesDialogTabs
            tab={tab}
            handleTabChange={handleTabChange}
          />

          {tab === "saveAsNew" && (
            <SaveSimplifiedFilterTemplatesDialogSaveAsNew
              newFilterTemplateName={newFilterTemplateName}
              setNewFilterTemplateName={setNewFilterTemplateName}
              setSaveButtonDisabled={setDisabled}
            />
          )}

          {tab === "rewriteExisting" && (
            <SaveSimplifiedFilterTemplatesDialogRewriteExisting
              selectedFilterTemplateId={selectedFilterTemplateId}
              setSelectedFilterTemplateId={setSelectedFilterTemplateId}
            />
          )}
        </Stack>
      </DialogContent>

      <DialogActions sx={{ px: 3, py: 2 }}>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={disabled || loading || !selectedFilterTemplateId}
          loading={loading}
          onClick={filterTemplateSaveHandler}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
