import { FilterFn } from "@tanstack/react-table";
import { normalizeString } from "./common";

export const accountDataFilter: FilterFn<any> = (row, columnId, value) => {
  const account = row.original.account ?? row.original.resource.account;
  const val = normalizeString(value);

  if (!account || typeof account !== "object") return false;

  return (
    normalizeString(account["provider_account_name"]).includes(val) ||
    normalizeString(account["provider_account_id"]).includes(val)
  );
};
