import { FC, useCallback } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { costBreakdownSetupChangeThunk } from "../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown/costBreakdownSetupChangeThunk";
import { Dates } from "../../../../../../../../../../services/cloudchipr.api";
import { costBreakdownV2DateRangeChangeThunk } from "../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/setup-change/costBreakdownV2DateRangeChangeThunk";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";
import { WidgetSetupDateRangeSelectV2 } from "../../../common/toolbar/date-picker-v2/WidgetSetupDateRangeSelectV2";
import { WidgetSetupDateRangeSelect } from "../../../common/toolbar/date-picker/WidgetSetupDateRangeSelect";

export const CostBreakdownV2WidgetSetupDateRangeSelect: FC = () => {
  const dispatch = useAppDispatch();
  const enableCostBreakdownNewEndpoints = useFlag(
    "EnableCostBreakdownNewEndpoints",
  );

  const oldDate = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("date"),
  );

  const dateChangeHandler = useCallback(
    (date: any) => {
      dispatch(costBreakdownSetupChangeThunk({ date }));
    },
    [dispatch],
  );

  const dateRangeChangeHandler = useCallback(
    (dates: Dates) => {
      dispatch(costBreakdownV2DateRangeChangeThunk(dates));
    },
    [dispatch],
  );

  if (enableCostBreakdownNewEndpoints) {
    return (
      <WidgetSetupDateRangeSelectV2 onDateChange={dateRangeChangeHandler} />
    );
  }

  return (
    <WidgetSetupDateRangeSelect dates={oldDate} onChange={dateChangeHandler} />
  );
};
