import {
  CircularProgress,
  ListItemText,
  Menu,
  MenuItem,
  PopoverOrigin,
} from "@mui/material";
import { FC, useCallback } from "react";
import { useToggle } from "rooks";
import { useAppDispatch } from "../../../../../../store/hooks";
import { saveFilterTemplateThunk } from "../../../../../../store/live-usage-mgmt/thunks/filter-template/saveFilterTemplateThunk";

interface SimplifiedFilterTemplatesMenuProps {
  open: boolean;
  onClose(): void;
  anchor: HTMLElement | null;
  onSaveAsClick(): void;
}

export const SimplifiedFilterTemplatesMenu: FC<
  SimplifiedFilterTemplatesMenuProps
> = ({ open, onClose, anchor, onSaveAsClick }) => {
  const dispatch = useAppDispatch();
  const [loading, toggleLoading] = useToggle(false);

  const filterTemplateSaveHandler = useCallback(async () => {
    toggleLoading();
    await dispatch(saveFilterTemplateThunk());
    onClose();
    toggleLoading();
  }, [dispatch, onClose, toggleLoading]);

  const handleSaveAsClick = useCallback(() => {
    onSaveAsClick();
    onClose();
  }, [onSaveAsClick, onClose]);

  return (
    <Menu
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      transformOrigin={transformOrigin}
    >
      <MenuItem
        dense
        sx={menuItemSx}
        onClick={filterTemplateSaveHandler}
        disabled={loading}
      >
        <ListItemText>Save</ListItemText>
        {loading && <CircularProgress disableShrink size={14} />}
      </MenuItem>
      <MenuItem dense sx={menuItemSx} onClick={handleSaveAsClick}>
        <ListItemText>Save as</ListItemText>
      </MenuItem>
    </Menu>
  );
};

const menuItemSx = {
  pl: 1,
  pr: 2,
};
const transformOrigin: PopoverOrigin = { vertical: -5, horizontal: 5 };
