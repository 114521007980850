import { FC, Fragment, useCallback } from "react";
import { CostBreakdownViewTypeSelect } from "./app-bar/CostBreakdownViewTypeSelect";
import { CostBreakdownWidgetSetupContent } from "./content/CostBreakdownWidgetSetupContent";
import { CostBreakdownResourceExplorerGroupingSelect } from "./app-bar/CostBreakdownResourceExplorerGroupingSelect";
import { WidgetSetupLayout } from "../common/WidgetSetupLayout";
import { WidgetSetupHeader } from "../common/WidgetSetupHeader";
import { WidgetForecastSelect } from "../common/toolbar/WidgetForecastSelect";
import { WidgetFrequencySelect } from "../common/toolbar/WidgetFrequencySelect";
import { WidgetsViewsHierarchySelect } from "../common/widgets-views-hierarchy-select/WidgetsViewsHierarchySelect";
import { WidgetSetupDateRangeSelect } from "../common/toolbar/date-picker/WidgetSetupDateRangeSelect";
import { scrollPageToBottom } from "../../../../../../utils/helpers/page-scrolling-functions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { costBreakdownSetupChangeThunk } from "../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown/costBreakdownSetupChangeThunk";
import { costBreakdownWidgetViewSelectChangeThunk } from "../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown/costBreakdownWidgetViewSelectChangeThunk";
import { resourceExplorerViewByIdLoadingSelector } from "../../../../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerViewByIdLoadingSelector";
import { costBreakdownWidgetForecastedOptionsSelector } from "../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/costBreakdownWidgetForecastedOptionsSelector";
import { costBreakdownSetupPropertyByKeySelector } from "../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { buildCostBreakdownWidgetThunk } from "../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown/buildCostBreakdownWidgetThunk";
import { costBreakdownSubmitButtonDisabledSelector } from "../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSubmitButtonDisabledSelector";

interface CostBreakdownSetupProps {
  onClose(): void;
  onSubmit(): void;
}

export const CostBreakdownSetup: FC<CostBreakdownSetupProps> = ({
  onClose,
  onSubmit,
}) => {
  const dispatch = useAppDispatch();

  const viewId = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("viewId"),
  );
  const setupDate = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("date"),
  );
  const frequency = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("frequency"),
  );
  const viewType = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("viewType"),
  );
  const forecastOption = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("forecastOption"),
  );
  const widgetId = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("id"),
  );

  const viewGettingLoading = useAppSelector((state) =>
    resourceExplorerViewByIdLoadingSelector(state, viewId),
  );

  const forecastedCostsOptions = useAppSelector((state) =>
    costBreakdownWidgetForecastedOptionsSelector(state, widgetId),
  );
  const setupId = useAppSelector(costBreakdownSetupPropertyByKeySelector("id"));
  const disabled = useAppSelector(costBreakdownSubmitButtonDisabledSelector);

  const dateChangeHandler = useCallback(
    (dates: any) => {
      dispatch(costBreakdownSetupChangeThunk({ dates }));
    },
    [dispatch],
  );

  const frequencyChangeHandler = useCallback(
    (e: any) => {
      dispatch(costBreakdownSetupChangeThunk({ frequency: e.target.value }));
    },
    [dispatch],
  );
  const forecastChangeHandler = useCallback(
    (event: any) => {
      dispatch(
        costBreakdownSetupChangeThunk({
          forecastOption: event.target.value,
        }),
      );
    },
    [dispatch],
  );

  const viewChangeHandler = useCallback(
    (id: string) => {
      dispatch(costBreakdownWidgetViewSelectChangeThunk(id));
    },
    [dispatch],
  );

  const submitHandler = useCallback(async () => {
    const responseId = await dispatch(buildCostBreakdownWidgetThunk()).unwrap();
    onSubmit();
    if (responseId && !setupId) {
      scrollPageToBottom();
    }
  }, [dispatch, setupId, onSubmit]);

  if (!viewType) {
    return null;
  }

  return (
    <Fragment>
      <WidgetSetupHeader
        disabled={disabled}
        onAdd={submitHandler}
        onCancel={onClose}
      />

      <WidgetSetupLayout
        empty={!viewId ? "Select Resource Explorer View to get started." : ""}
        appBar={
          <Fragment>
            <WidgetsViewsHierarchySelect
              selectedItemId={viewId}
              onChange={viewChangeHandler}
            />

            <CostBreakdownResourceExplorerGroupingSelect
              disabled={viewGettingLoading}
            />
            <WidgetSetupDateRangeSelect
              dates={setupDate}
              onChange={dateChangeHandler}
              disabled={viewGettingLoading}
            />
            <WidgetFrequencySelect
              frequency={frequency}
              onChange={frequencyChangeHandler}
              disabled={
                ["pie", "numeral", "table"].includes(viewType) ||
                viewGettingLoading
              }
            />
            <CostBreakdownViewTypeSelect />
            <WidgetForecastSelect
              forecast={forecastOption}
              onChange={forecastChangeHandler}
              options={forecastedCostsOptions}
            />
          </Fragment>
        }
      >
        <CostBreakdownWidgetSetupContent />
      </WidgetSetupLayout>
    </Fragment>
  );
};
