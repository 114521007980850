import { FC, useCallback } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { commitmentsDataViewTabSelector } from "../../../../../../../../store/commitments/selectors/common/view-options/commitmentsDataViewTabSelector";
import { setCommitmentsDataViewTab } from "../../../../../../../../store/commitments/commitmentsSlice";
import { DataViewTab } from "../../../../../../../../store/commitments/utils/types/types";
import { reservationsGranularDataTotalUtilizationSelector } from "../../../../../../../../store/commitments/selectors/reservations/data/granular/totals/reservationsGranularDataTotalUtilizationSelector";
import { reservationsGranularDataTotalCoverageSelector } from "../../../../../../../../store/commitments/selectors/reservations/data/granular/totals/reservationsGranularDataTotalCoverageSelector";

export const ReservationsDataGridViewTabs: FC = () => {
  const dispatch = useAppDispatch();

  const tab = useAppSelector(commitmentsDataViewTabSelector);

  const totalUtilization = useAppSelector(
    reservationsGranularDataTotalUtilizationSelector,
  );

  const totalCoverage = useAppSelector(
    reservationsGranularDataTotalCoverageSelector,
  );

  const tabChangeHandler = useCallback(
    (_: unknown, tab: string | null) => {
      if (!tab) {
        return;
      }

      dispatch(setCommitmentsDataViewTab(tab as DataViewTab));
    },
    [dispatch],
  );

  return (
    <ToggleButtonGroup
      exclusive
      size="small"
      value={tab}
      color="primary"
      sx={{ borderRadius: 2 }}
      onChange={tabChangeHandler}
    >
      <ToggleButton value="commitment" sx={toggleButtonSx}>
        Reservations{" "}
        {!!totalUtilization?.percent &&
          `(Utilization ${totalUtilization?.percent}%)`}
      </ToggleButton>

      <ToggleButton value="coverage" sx={toggleButtonSx}>
        Coverage {!!totalCoverage?.percent && `(${totalCoverage?.percent}%)`}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const toggleButtonSx = {
  textTransform: "none",
  borderRadius: 2,
  fontSize: 14,
  px: 2,
};
